import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import jobPost from '../../assets/images/jobpost.png';
import Button from '../atoms/Button';
import LText from '../atoms/LText';
import SectionTitle from '../atoms/SectionTitle';
import Text from '../atoms/Text';
import XXLText from '../atoms/XXLText';

const LandingPageCmsSection = () => {
	const { t, i18n } = useTranslation();
	return (
		<section className="py-20 pt-[120px]" id="about">
			<SectionTitle>{t('about')}</SectionTitle>

			<div className="mb-10">
				<div className="mx-auto mb-24 flex flex-col items-center px-4">
					{/* <img src={Logo} alt="" /> */}
					<XXLText className="my-[10px] text-center md:my-[38px]">
						Your Partner in Efficient Talent Acquisition
					</XXLText>
					<LText className="mx-auto max-w-[950px] text-center">
						{t('With')} <span className="font-semibold text-[#1098FF]">HrClerks</span>{' '}
						{t(
							'ATS – We are offering a streamlined recruitment process empowering HR professionals and recruitment agencies to take control. From unified talent rating to personalized talent pooling, HrClerks ATS is your gateway to a seamless, modern hiring experience. Sign up today and start hiring in minutes.'
						)}
					</LText>
				</div>
			</div>
			<div className="mt-10 flex flex-col gap-3 px-5 md:flex-row lg:gap-5 xl:gap-24">
				<div className="flex-[60%]">
					<img className="" src={jobPost} alt="Simplified way to post" />
				</div>
				<div className="ml-auto flex flex-[40%] flex-col justify-center">
					<XXLText className="max-w-[370px]">{t('Simplified way to post a job')}</XXLText>

					<Text className="my-[41px] max-w-[500px]">
						{t('Most easiest task is job posting. Really?')} <br />
						{t('If you don’t agree, please')}
						{i18n?.language === 'en' && <span className="text-[#1098FF]">join</span>}
						{t('our advanced')}{' '}
						<span className="font-semibold">Applicant Tracking System (ATS)</span>
						{t('solutions.')}
						{i18n?.language === 'bn' && <span className="text-[#1098FF]">যোগ দিন।</span>}
					</Text>

					<Link to="/signup">
						<Button
							title={t('Get Started')}
							className="text-bse w-48 rounded-md bg-[#1098FF] py-3 font-semibold text-[#fff]"
						/>
					</Link>
				</div>
			</div>
		</section>
	);
};

export default LandingPageCmsSection;
