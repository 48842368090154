import { useMutation } from '@tanstack/react-query';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { setNewPasswordAPI } from '../../apis/resetPassword';
import Error from '../atoms/Error';
import PasswordInputField from '../atoms/PasswordInputField';
import LanguageBtn from '../molecules/LanguageBtn';

// eslint-disable-next-line no-unused-vars
const NewPasswordStep = ({ onPrev, onNext, token = '', email = '' }) => {
	const { t } = useTranslation();
	const navigateTo = useNavigate();
	const [matchError, setMatchError] = useState('');

	// initialValues
	const newPasswordInitialValues = {
		newPassword: '',
		retypeNewPassword: '',
	};

	// validationSchema
	const newPasswordValidationSchema = Yup.object().shape({
		newPassword: Yup.string()
			.min(8, 'Password must be at least 5 characters')
			.required('Password is required'),
		retypeNewPassword: Yup.string()
			.min(8, 'Password must be at least 5 characters')
			.required('Password is required'),
	});

	const { mutateAsync } = useMutation({ mutationFn: setNewPasswordAPI });

	// onSubmit
	const onSubmit = async (values, { resetForm }) => {
		const data = {
			token,
			password: values.newPassword,
		};

		if (values.newPassword !== values.retypeNewPassword) {
			setMatchError('New Password and Retype New password does not match.');
			return;
		}
		setMatchError('');
		const response = await mutateAsync(data);
		if (response.success) {
			toast.success('Password successfully changed.');
			navigateTo('/signin');
		}
		resetForm();
	};

	return (
		<section className="flex w-full flex-col bg-white">
			<LanguageBtn
				className="absolute end-5 top-5 sm:end-12 sm:top-12"
				language={localStorage.getItem('Language')}
			/>
			<div className="mx-auto text-center">
				<div className="w-full max-w-[410px]">
					<h4 className="text-gray-0 text-center font-Montserrat  text-xl font-bold leading-[21.94px]">
						{t('Reset Your Password')}
					</h4>

					<h4 className="mb-[48px] mt-2 text-center font-Montserrat text-base font-semibold leading-[29.26px] text-[#5F6D83]">
						{t('Please enter your new password & reconfirm your password.')}
					</h4>
				</div>
				{matchError !== '' && <Error error={matchError} className="max-w-[400px] text-left" />}

				<Formik
					initialValues={newPasswordInitialValues}
					validationSchema={newPasswordValidationSchema}
					onSubmit={onSubmit}
				>
					{/*  eslint-disable-next-line no-unused-vars */}
					{({ values, isSubmitting, errors, touched }) => {
						return (
							<Form>
								<fieldset>
									<PasswordInputField
										name="newPassword"
										placeholder={t('Type new password')}
										id="newPassword"
										className="h-[48px] w-full rounded-[8px] border-0 font-semibold text-[#5F6D83] drop-shadow-md sm:w-[400px]"
										error={errors.newPassword && touched.newPassword && errors.newPassword}
									/>

									<PasswordInputField
										name="retypeNewPassword"
										placeholder={t('Re-type new password')}
										id="retypeNewPassword"
										className="h-[48px] w-full rounded-[8px] border-0 font-semibold text-[#5F6D83] drop-shadow-md sm:w-[400px]"
									/>

									<button
										type="submit"
										className="mt-6 h-[44px] w-full max-w-[410px] cursor-pointer rounded-lg bg-[#1098FF] font-Montserrat text-white outline-none"
									>
										{t('Confirm Now')}
									</button>
								</fieldset>
							</Form>
						);
					}}
				</Formik>
			</div>
		</section>
	);
};

export default NewPasswordStep;
