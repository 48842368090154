/* eslint-disable jsx-a11y/control-has-associated-label */
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from 'react-scroll';
import atsBannerPageImg from '../../assets/images/DashboardBanner.png';
import card from '../../assets/images/ats-banner-card.png';
import CricleWrite from '../../assets/svgs/Cricle-write';
import SeniorProductionDesign from '../../assets/svgs/SeniorProductionDesign';
import Button from '../atoms/Button';

const HeroSection = () => {
	const { t, i18n } = useTranslation();

	return (
		<section className="hero-bg h-screen" id="home">
			<div className="container mx-auto flex h-full items-center">
				<div className="flex w-full flex-col items-center pl-[20px] pt-[128px]">
					<div className="md:w-[689px]">
						<div className="mx-auto my-5">
							<div className="mb-5">
								<h1 className="esx:leading-[72px] mb-[35px] font-Montserrat text-4xl text-[30px] font-bold leading-10 text-[#02060C] sm:text-[40px] sm:leading-[72px] md:text-[60px]">
									{t('Effortless Hiring with')} <br />
									{i18n.language === 'en' && (
										<span className="text-[#1098FF]">{t('HrClerks ATS')}</span>
									)}
								</h1>
								<p
									className="pr-2 font-medium leading-9 text-[#5F6D83] exs:text-lg sm:pr-0 md:pr-[60px]"
									style={{ lineHeight: '36px' }}
								>
									{t(
										'Post jobs, get candidates and manage applicants all in one place. Get started today!'
									)}
								</p>
							</div>
							<RouterLink to="/signup">
								<Button
									title={t('Start For Free')}
									className="mt-[50px] h-[60px] rounded-[5px] bg-[#1098FF] px-[40px] text-base font-semibold text-white"
								/>
							</RouterLink>
							<div className="mt-[18px] flex items-center">
								<CricleWrite />
								<Link to="pricing" smooth duration={800}>
									<p className="block cursor-pointer border-0 border-b border-blue-500 font-Montserrat text-[12px] font-medium text-[#1098FF]">
										{t('Upgrade from Free')}
									</p>
								</Link>
							</div>
						</div>
					</div>
				</div>

				<div className="relative hidden h-full w-full lg:block">
					<div className="bannerImg1 absolute bottom-0 start-[50px] h-[70%] w-[135%] rounded-[12px] ">
						<div className="absolute -top-[0px] z-10 lg:start-[240px] 2xl:start-[50%]">
							<SeniorProductionDesign className="bannerImg1" />
						</div>
						{/* <JobSettings className="bannerImg1 absolute start-1/2 top-1/3 h-[300px] w-[250px]" /> */}
						<img
							src={atsBannerPageImg}
							className="absolute -start-[20%] block h-[calc(100%+170px)] w-full rounded-[12px] object-cover"
							alt="Ats Banner Page"
						/>
					</div>
					<img
						src={card}
						className="bannerImg1 xxl:h-[46%] xxl:w-[42%] absolute -bottom-[8%] start-[0px] z-10 w-[50%]"
						alt="ATS Banner Bottom card"
					/>
				</div>
			</div>
		</section>
	);
};

export default HeroSection;
