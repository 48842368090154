import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import personProfile from '../../assets/images/person-profile.png';
import Button from '../atoms/Button';
import CircleTick from '../atoms/CircleTick';
import Heading from '../atoms/Heading';
import Text from '../atoms/Text';

const SortTalentsSection = () => {
	const { t } = useTranslation();

	return (
		<section className="container mx-auto mb-[50px] mt-[120px]">
			<div className="flex w-full flex-col gap-5 px-5 lg:flex-row lg:justify-between">
				<div className="flex-[50%]">
					<div className="w-full lg:max-w-[471px]">
						<Heading
							className="leading-[52px] lg:max-w-[430px]"
							text={t('Easiest way to sort talents')}
						/>

						<Text className="my-5 pr-5 font-Montserrat text-base font-medium leading-[30px] text-[#687497]">
							{t(
								'Choose the applicants easily by their rating provided stakeholders or can you can judge them on your own way'
							)}
						</Text>
						<div>
							<div className="my-2 flex gap-2 px-3">
								<CircleTick className="mt-1" />
								<h5 className="mt-0 font-Montserrat text-base font-bold leading-[30px] text-[#02060C] md:text-[18px]">
									{t('Keep rating your talents')} &nbsp;{' '}
									<span className="text-sm font-medium text-[#687497] md:text-base">
										{' '}
										{t('You can rate the applicant and can see what other')}{' '}
									</span>
								</h5>
							</div>
							<div className="flex gap-2 px-3">
								<CircleTick className="mt-1" />
								<h5 className="mt-0 font-Montserrat text-base font-bold leading-[30px] text-[#02060C] md:text-[18px]">
									{t('Trust other stakeholders rating')} &nbsp;{' '}
									<span className="text-sm font-medium text-[#687497] md:text-base">
										{' '}
										{t('You can go with the talents rating provided by other stakeholders')}
									</span>
								</h5>
							</div>
						</div>
						<Link to="/signup">
							<Button
								title={t('Get Started')}
								className="text-bse my-5 w-48 rounded-md bg-[#1098FF] py-3 font-semibold text-[#fff]"
							/>
						</Link>
					</div>
				</div>
				<div className="mx-auto flex-[50%]">
					<img src={personProfile} alt="Easiest way to sort talents" className="h-full w-full" />
				</div>
			</div>
		</section>
	);
};

export default SortTalentsSection;
