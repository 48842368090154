import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import DropdownArrow from '../../assets/svgs/DropdownArrow';

const BackButton = ({ children = null, className = '' }) => {
	const navigate = useNavigate();
	const { t } = useTranslation();

	return (
		<button
			type="button"
			onClick={() => navigate(-1)}
			className={
				className ||
				`absolute start-5 top-5 z-50 flex items-center gap-2 font-semibold text-[#1098FF] sm:left-[50px] sm:top-[50px]`
			}
		>
			<DropdownArrow className="rotate-90" />
			{children || t('Back')}
		</button>
	);
};

export default BackButton;
