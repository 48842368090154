const Heading = ({
	text = '',
	className = '',
	headingColorTextClass = '',
	headingColorText = '',
	style = {},
}) => {
	return (
		<h2
			className={`font-Montserrat text-xl font-bold leading-tight text-[#02060C] md:text-[32px] ${className}`}
			style={style}
		>
			{text}
			<span className={`${headingColorTextClass}`}>{headingColorText}</span>
		</h2>
	);
};

export default Heading;
