const EmailButton = ({
	className = '',
	email = 'sales@hrclerks.com',
	subject = 'Price inquiry for HrClerks ATS',
	children = null,
}) => {
	const handleClick = () => {
		// Replace 'recipient@example.com' with the desired email address
		const emailAddress = email;

		// Construct the mailto link
		const mailtoLink = `mailto:${emailAddress}?subject=${subject}`;

		// Open the default email client with the mailto link
		window.location.href = mailtoLink;
	};

	return (
		<button type="button" onClick={handleClick} className={className}>
			{children}
		</button>
	);
};

export default EmailButton;
