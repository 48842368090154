import { TiTick } from 'react-icons/ti';

const CircleTick = ({ className = '' }) => {
	return (
		<div
			className={`flex h-4 w-4 items-center justify-center rounded-full border-2 border-[#1098FF] bg-[#DEE8F1] ${className}`}
		>
			<TiTick className="text-[#1098FF]" />
		</div>
	);
};

export default CircleTick;
