/* eslint-disable consistent-return */
import api from './config/axiosInstance';

// const authAPIsAxiosInstance = axios.create({
// 	baseURL: `${baseURL}/api/en/v1/users`,
// 	timeout: 10000,
// });

const resetPasswordAPIs = {
	async inputEmailAPI(data) {
		try {
			const response = await api.post('/users/forget-password', data);
			return response.data;
		} catch (error) {
			return error.response.data;
		}
	},

	async inputOtpAPI(data) {
		try {
			const response = await api.post('users/otp', data);
			return response.data;
		} catch (error) {
			return error.response.data;
		}
	},

	async setNewPasswordAPI(data) {
		const response = await api.post('/users/reset-password-by-token', data);
		return response.data;
	},
};

export const { inputEmailAPI, inputOtpAPI, setNewPasswordAPI } = resetPasswordAPIs;

export default resetPasswordAPIs;
