import { useTranslation } from 'react-i18next';
import DubbleCommaSvg from '../../assets/svgs/DubbleCommaSvg';
import CircleTick from '../atoms/CircleTick';
import Heading from '../atoms/Heading';
import Text from '../atoms/Text';
import SalesContactForm from '../organisms/SalesContactForm';

const ContactSales = () => {
	const { t } = useTranslation();

	return (
		<section className="container mx-auto flex px-5">
			<div className="w-full lg:w-[45%]">
				<div className="absolute start-0 top-0 z-0 h-[800px] w-screen bg-[#223645]" />
				<div className="z-20 mb-32 flex h-[500px] items-end pt-[10%] lg:h-[800px] lg:items-center lg:justify-center">
					<div className="relative max-w-[500px]">
						<Heading
							text={t('Contact our sales team')}
							className="mb-[40px] w-full whitespace-nowrap text-[24px] font-bold text-white lg:text-[32px] xl:text-[42px]"
						/>
						<Text className="mb-[24px] leading-[30px] text-white">
							{t(`We're happy to answer questions and get you acquainted with HrClerks ATS.`)}
						</Text>
						<div className="flex flex-col gap-[7px] px-[10px]">
							<div className="flex items-center gap-[15px]">
								<CircleTick className="h-[19px] w-[19px]" />
								<h5 className="mt-0 font-Montserrat text-base font-bold leading-[30px] text-white md:text-[18px]">
									{t('Learn how to increase hiring teams productivity')}
								</h5>
							</div>
							<div className="flex items-center gap-[15px]">
								<CircleTick className="h-[19px] w-[19px]" />
								<h5 className="mt-0 font-Montserrat text-base font-bold leading-[30px] text-white md:text-[18px]">
									{t('Get pricing information')}
								</h5>
							</div>
							<div className="flex items-center gap-[15px]">
								<CircleTick className="h-[19px] w-[19px]" />
								<h5 className="mt-0 font-Montserrat text-base font-bold leading-[30px] text-white md:text-[18px]">
									{t('Explore use cases for your team')}
								</h5>
							</div>
						</div>
					</div>
				</div>

				<SalesContactForm className="relative my-0 mt-20 block rounded-sm sm:mt-10 sm:p-14 lg:hidden" />

				<div className="container my-20 flex items-center lg:justify-center">
					<div className="max-w-[500px] flex-1">
						<div className="flex">
							<p className="mt-[30px] max-w-[562px] font-semibold leading-[30px] text-[#687497] md:text-[20px]">
								{t('Revolutionizing recruitment with')}{' '}
								<span className="font-semibold text-[#1098FF]">HrClerks</span>{' '}
								{t(
									"ATS – where simplicity meets innovation. Let's build successful teams together."
								)}
							</p>
						</div>
						<div className="flex justify-between">
							<div className="mt-[40px]">
								<p className="mb-[10px] text-base font-semibold text-[#687497] md:text-[18px]">
									{t('Nabila Afrose')}
								</p>
								<p className="text-sm font-medium text-[#687497] md:text-base">
									{t('CEO, HrClerks Talent Solutions')}
								</p>
							</div>
							<DubbleCommaSvg className="absolute bottom-0 end-0 h-full w-full" />
						</div>
					</div>{' '}
				</div>
			</div>
			<SalesContactForm className="my-60 hidden w-[55%] rounded-sm p-14 lg:block" />
		</section>
	);
};

export default ContactSales;
