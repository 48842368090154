import { useTranslation } from 'react-i18next';
import applyFormImage from '../../assets/images/ApplyFormImage.png';
import Heading from '../atoms/Heading';
import Text from '../atoms/Text';

const TalentTaggingSection = () => {
	const { t } = useTranslation();

	return (
		<section className="container mx-auto my-32">
			<div className="flex w-full flex-col gap-5 px-6 lg:flex-row lg:justify-between">
				<div className="flex flex-[50%] flex-col justify-center">
					<div className="w-full">
						<div className="relative">
							<div className="outlined-number">06</div>
							<Heading
								text={t('Custom Screening Questions')}
								className="relative bottom-8 whitespace-pre-wrap leading-[52px] exs:whitespace-nowrap md:text-[32px]"
								style={{ fontSize: '32px' }}
							/>
							<Text className="mb-5 max-w-[500px] leading-[30px]">
								{t(
									'Elevate your hiring process with our exclusive feature – Custom Screening Questions. Tailor-made queries help pinpoint the ideal candidates, while effortlessly isolating those who may not be the best fit, saving you valuable time in the selection process.'
								)}
							</Text>
						</div>
					</div>
				</div>
				<div className="mx-auto flex-[50%]">
					<img src={applyFormImage} alt="Custom Screening Questions" className="h-full w-full" />
				</div>
			</div>
		</section>
	);
};

export default TalentTaggingSection;
