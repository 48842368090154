/* eslint-disable no-unused-vars */
/* eslint-disable no-alert */
import { useMutation } from '@tanstack/react-query';
import { Formik } from 'formik';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { inputEmailAPI } from '../../apis/resetPassword';
import TextInputField from '../atoms/TextInputField';

const FirstEmailStep = ({ className = '' }) => {
	const { t } = useTranslation();
	const navigateTo = useNavigate();
	const [email, setEmail] = useState('');
	const emailValidationSchema = Yup.object().shape({
		email: Yup.string().email('Invalid email').required('Email is required'),
	});

	const { mutateAsync } = useMutation({
		mutationFn: inputEmailAPI,
		// onSuccess: (file) => {
		// 	navigateTo(`/signup/confirmation?query=${email}`);
		// 	toast.success(file?.message);
		// },
	});

	const onSubmit = async (values, { setSubmitting }) => {
		setEmail(values.email);
		// setTimeout(() => {
		// alert(JSON.stringify(values, null, 2));
		setSubmitting(false);
		const response = await mutateAsync(values);
		if (response.success) {
			navigateTo(`/signup/confirmation?query=${values.email}&&key=${'signup'}`);
			toast.success(response?.message);
		} else if (!response.success) {
			toast.error(response.message);
		}
	};

	return (
		<div className={className}>
			<Formik
				initialValues={{ email: '' }}
				validationSchema={emailValidationSchema}
				onSubmit={onSubmit}
			>
				{({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
					<form onSubmit={handleSubmit} className="flex justify-center">
						<fieldset className="w-[410px]">
							<TextInputField
								type="email"
								name="email"
								onChange={handleChange}
								onBlur={handleBlur}
								value={values.email}
								placeholder={t('Email')}
								error={errors.email && touched.email && errors.email}
								className="mx-auto h-[48px] max-w-[410px] drop-shadow-md"
								// errorClass="absolute left-1/2 -translation-x-1/2 w-[410px]"
							/>

							<button
								type="submit"
								disabled={isSubmitting}
								className="h-[48px] w-full max-w-[410px] cursor-pointer rounded-lg bg-[#1098FF] font-Montserrat text-white outline-none"
							>
								{t('Send Verification Code')}
							</button>
						</fieldset>
					</form>
				)}
			</Formik>
		</div>
	);
};

export default FirstEmailStep;
