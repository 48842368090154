/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';
import { Link, useLocation } from 'react-router-dom';

import Logo from '../../assets/images/HrClerk.png';
import Button from '../atoms/Button';
import LanguageBtn from './LanguageBtn';
import NavLinks from './NavLinks';

const Navbar = ({ activeSection = '', language = '', setLanguage = null }) => {
	const { t } = useTranslation();
	const [open, setOpen] = useState(false);
	const location = useLocation();

	const handleScrollToTop = () => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
	};

	return (
		<nav className="fixed left-[50%] z-50 h-[105px] w-full -translate-x-[50%] bg-white px-[10px]">
			<div className="container relative mx-auto flex h-full items-center justify-between font-medium">
				<div className="z-50 flex w-full justify-between lg:w-auto">
					<Link to="/" onClick={handleScrollToTop}>
						<div className="flex justify-end align-bottom">
							<img src={Logo} alt="logo" className="h-[45px] md:cursor-pointer" />
							{/* TODO: Remove beta tag when stable version released */}
							<span className="mt-7 text-sm text-gray-300">Beta</span>
						</div>
					</Link>
				</div>

				<ul className="hidden items-center gap-5 font-[Poppins] uppercase lg:flex lg:gap-8">
					{(location.pathname === '/' || location.pathname.includes('/open/job')) && (
						<NavLinks activeSection={activeSection} />
					)}
				</ul>

				<div className="flex flex-row gap-6 p-4 pr-0 xl:gap-7 xl:pr-4">
					<LanguageBtn className="relative" language={language} setLanguage={setLanguage} />

					<Link to="/signin" className="hidden cursor-pointer lg:block">
						<Button
							title="Sign In"
							className="h-[45px] rounded-md py-2 text-[16px] font-semibold text-[#5F6D83]"
						/>
					</Link>

					{/* <Link to="/signup" className="hidden cursor-pointer lg:block">
						<Button
							title="Sign Up"
							className="h-[45px] w-[120px] rounded-md bg-[#1098FF] py-2 text-[18px] font-semibold text-white"
						/>
					</Link> */}

					<Link to="/contact-sales" className="hidden cursor-pointer lg:block">
						<Button
							title={t('Talk to Sales')}
							className="h-fit w-fit rounded-md border border-[#1098FF] px-2 py-3 text-[16px] font-bold text-[#1098FF] xl:px-7"
						/>
					</Link>

					<Link to="/signup" className="hidden cursor-pointer lg:block">
						<Button
							title={t('Try for Free')}
							className="h-fit w-fit rounded-md border border-[#1098FF] bg-[#1098ff] px-2 py-3 text-[16px] font-bold text-white xl:px-7"
						/>
					</Link>
				</div>

				{/* Mobile nav */}
				<ul
					className={`
      fixed bottom-0 top-0 flex h-screen w-full flex-col items-center justify-center gap-8 overflow-y-auto bg-white py-24 pl-4 duration-500
      lg:hidden ${open ? 'left-0 z-50' : 'left-[-100%]'}
      `}
				>
					{(location.pathname === '/' || location.pathname.includes('/open/job')) && (
						<NavLinks setOpen={setOpen} />
					)}

					<Link to="/signin" className="cursor-pointer">
						<Button
							title="Signin"
							className="rounded-md px-5 py-2 text-base font-semibold text-[#5F6D83]"
						/>
					</Link>

					<div className="flex">
						<Link to="/contact-sales" className="cursor-pointer">
							<Button
								title={t('Talk to Sales')}
								className="ml-5 h-[45px] w-[120px] rounded-md border border-[#1098FF] py-2 text-[16px] font-bold text-[#1098FF]"
							/>
						</Link>

						<Link to="/signup" className="cursor-pointer">
							<Button
								title={t('Try for Free')}
								className="ml-5 h-[45px] w-[120px] rounded-md border border-[#1098FF] bg-[#1098ff] py-2 text-[16px] font-bold text-white"
							/>
						</Link>
					</div>
				</ul>
				<div className="z-50 ms-3 cursor-pointer text-3xl lg:hidden" onClick={() => setOpen(!open)}>
					{open ? <AiOutlineClose /> : <AiOutlineMenu />}
				</div>
			</div>
		</nav>
	);
};

export default Navbar;
