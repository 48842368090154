import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import HrClerks from '../assets/images/HrClerks.png';
import openGraphImage from '../assets/images/open-geaph-image.png';
import SignUpConfirmation from '../assets/images/signUpConfirmation.png';
import BackButton from '../components/atoms/BackButton';
import Text from '../components/atoms/Text';
import LanguageBtn from '../components/molecules/LanguageBtn';
import FirstEmailStep from '../components/templates/FirstEmailStep';

const ForgetPasswordEmailPage = () => {
	const { t } = useTranslation();

	return (
		<>
			<Helmet>
				<link rel="canonical" href="https://hrclerks.com" />
				<title>Reset Your Password by Email | Elevate your hiring process with HrClerks ATS</title>
				<meta
					name="description"
					content="Reset Your Password by Email | Elevate your hiring process with HrClerks ATS – a cutting-edge Applicant Tracking System designed to simplify recruitment. Explore powerful features for seamless talent management and transform your hiring experience today."
				/>
				{/* Open Graph */}
				<meta property="article:author" content="HrClerks Talent Solution" />
				<meta property="article:publisher" content="https://www.hrclerks.com" />
				<meta property="og:site_name" content="hrclerks.com" />
				<meta
					name="og:title"
					content="Reset Your Password by Email | Elevate your hiring process with HrClerks ATS"
				/>
				<meta
					name="og:description"
					content="Reset Your Password by Email | Elevate your hiring process with HrClerks ATS – a cutting-edge Applicant Tracking System designed to simplify recruitment. Explore powerful features for seamless talent management and transform your hiring experience today."
				/>
				<meta name="og:image" content={openGraphImage} />
				<meta property="og:image:width" content="1200" />
				<meta property="og:image:height" content="630" />
				<meta name="og:type" content="website" />
				<meta name="og:url" content="https://www.hrclerks.com" />
				{/* Twitter */}
				<meta property="article:author" content="HrClerks Talent Solution" />
				<meta property="article:publisher" content="https://www.hrclerks.com" />
				<meta property="twitter:site_name" content="hrclerks.com" />
				<meta
					name="twitter:title"
					content="Reset Your Password by Email | Elevate your hiring process with HrClerks ATS"
				/>
				<meta
					name="twitter:description"
					content="Reset Your Password by Email | Elevate your hiring process with HrClerks ATS – a cutting-edge Applicant Tracking System designed to simplify recruitment. Explore powerful features for seamless talent management and transform your hiring experience today."
				/>
				<meta name="twitter:image" content={openGraphImage} />
				<meta property="twitter:image:width" content="1200" />
				<meta property="twitter:image:height" content="630" />
				<meta name="twitter:url" content="https://www.hrclerks.com" />
				<meta
					name="twitter:card"
					content="Reset Your Password by Email | Elevate your hiring process with HrClerks ATS"
				/>
			</Helmet>

			<div className="fixed left-0 h-screen w-full md:flex">
				<BackButton />
				<LanguageBtn
					className="absolute end-5 top-5 sm:end-12 sm:top-12"
					language={localStorage.getItem('Language')}
				/>
				<img
					className="hidden h-full w-[45%] object-cover md:block"
					src={SignUpConfirmation}
					alt=""
				/>
				<div className="mx-auto flex h-full w-full flex-col justify-center bg-white px-10 text-center sm:px-0">
					<img
						src={HrClerks}
						alt="HrClerks logo"
						className="mx-auto mb-[40px] block w-[30%] object-contain md:hidden"
					/>
					<p className="mb-[18px] text-center font-Montserrat text-[20px] font-bold  text-black md:text-xl">
						{t('Reset Your Password')}
					</p>
					<Text className="mx-auto mb-[48px] max-w-[433px] text-center">
						{t('You will received an e-mail with a verification code. Please check you inbox.')}
					</Text>
					<FirstEmailStep />
				</div>
			</div>
		</>
	);
};

export default ForgetPasswordEmailPage;
