import { useTranslation } from 'react-i18next';

import DateFormate from '../../data/DateFormater';

const JobCardData = ({ values }) => {
	const { t } = useTranslation();

	return (
		<>
			<div className="text-sm font-semibold text-[#5F6D83]">
				<span>{values?.workplaceType}</span>, <span>{values?.location}</span>,{' '}
				<span>{values?.jobType}</span>
			</div>
			<div className="mb-3 mt-1 flex flex-wrap">
				<p className="me-4 whitespace-nowrap text-sm font-medium text-[#5AB180]">
					{t('Publish on')}: {values?.createdAt && DateFormate(values?.createdAt)}
				</p>
				<p className="whitespace-nowrap text-sm font-medium text-[#E15241]">
					{t('Expires on')}: {values?.expiredAt && DateFormate(values?.expiredAt)}
				</p>
			</div>
		</>
	);
};

export default JobCardData;
