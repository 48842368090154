import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
	email: Yup.string().email('Invalid email address').required('Required'),
	firstName: Yup.string().required('Required'),
	lastName: Yup.string().required('Required'),
	phone: Yup.string()
		.matches(/^([01]|\+88)?\d{10}$/, 'Invalid phone number')
		.required('Required'),
	presentAddress: Yup.string().required('Required'),
	parmanentAddress: Yup.string().required('Required'),
});
