const CopyIcon = ({ width = '20', height = '24', style = {}, className = '' }) => {
	return (
		<div>
			<svg
				width={width}
				height={height}
				viewBox={`0 0 ${width} ${height}`}
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
				style={style}
				className={className}
			>
				<path
					d="M13.4456 4.19629H2.28803C1.24108 4.19629 0.390747 5.04663 0.390747 6.09357V22.1025C0.390747 23.1495 1.24108 23.9998 2.28803 23.9998H13.4456C14.4926 23.9998 15.3429 23.1495 15.3429 22.1025V6.09357C15.338 5.04663 14.4877 4.19629 13.4456 4.19629ZM14.0109 22.0976C14.0109 22.4122 13.7553 22.6678 13.4407 22.6678H2.28312C1.96854 22.6678 1.71295 22.4122 1.71295 22.0976V6.09357C1.71295 5.779 1.96854 5.5234 2.28312 5.5234H13.4407C13.7553 5.5234 14.0109 5.779 14.0109 6.09357V22.0976Z"
					fill="currentColor"
				/>
				<path
					d="M17.712 0H6.55439C5.50744 0 4.6571 0.850337 4.6571 1.89728C4.6571 2.26593 4.95202 2.56084 5.32066 2.56084C5.68931 2.56084 5.98422 2.26593 5.98422 1.89728C5.98422 1.58271 6.23981 1.32712 6.55439 1.32712H17.712C18.0266 1.32712 18.2822 1.58271 18.2822 1.89728V17.9062C18.2822 18.2208 18.0266 18.4764 17.712 18.4764C17.3433 18.4764 17.0484 18.7713 17.0484 19.14C17.0484 19.5086 17.3433 19.8035 17.712 19.8035C18.7589 19.8035 19.6093 18.9532 19.6093 17.9062V1.89728C19.6093 0.850337 18.7589 0 17.712 0Z"
					fill="currentColor"
				/>
			</svg>
		</div>
	);
};

export default CopyIcon;
