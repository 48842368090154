const EwnSVG = () => {
	return (
		<svg
			width="229"
			height="54"
			viewBox="0 0 229 54"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M35.4324 0.819824L70.9622 0.819824L75.2364 5.62834L68.8345 10.4273L31.9023 10.4273L35.4324 0.819824Z"
				fill="#1098FF"
			/>
			<path
				d="M60.2895 53.1805L22.1551 53.1805L17.8809 48.3815L24.2827 43.5825L62.417 43.5825L60.2895 53.1805Z"
				fill="#1098FF"
			/>
			<path
				d="M0.658203 32.0187L58.9426 32.0187L61.0702 22.4111L4.17874 22.4111L2.42322 27.2102L0.658203 32.0187Z"
				fill="#00FFAB"
			/>
			<path
				d="M104.961 14.3506L103.359 23.0899H120.675L119.339 30.3408H101.975L100.277 39.6621L120.618 39.6621L119.177 47.1421L90.3164 47.1421L97.7677 6.87061L125.942 6.87061L124.51 14.3506L104.961 14.3506Z"
				fill="#02060C"
			/>
			<path
				d="M189.379 6.86963L169.678 47.1411H160.461L157.208 20.0454L143.736 47.1411H134.472L129.73 6.86963L138.25 6.86963L141.551 35.177L155.557 6.86963L163.552 6.86963L166.739 35.406L180.802 6.86963L189.379 6.86963Z"
				fill="#02060C"
			/>
			<path
				d="M228.313 6.86963L220.852 47.1411H213.773L199.872 21.944L195.188 47.1411H186.658L194.119 6.86963L201.256 6.86963L215.156 32.124L219.793 6.86963L228.313 6.86963Z"
				fill="#02060C"
			/>
		</svg>
	);
};

export default EwnSVG;
