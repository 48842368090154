import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import HrClerks from '../../assets/images/HrClerks.png';
import openGraphImage from '../../assets/images/open-geaph-image.png';
import signUpConfirmation from '../../assets/images/signUpConfirmation.png';
import BackButton from '../../components/atoms/BackButton';
import Text from '../../components/atoms/Text';
import LanguageBtn from '../../components/molecules/LanguageBtn';

const ForgotPasswordOTPPage = () => {
	const { t } = useTranslation();
	// const location = useLocation();
	// const searchParams = new URLSearchParams(location.search);
	// const email = searchParams.get('email');

	const [userEmail, setUserEmail] = useState('');
	// const [time, setTime] = useState({ minutes: 2, seconds: 59 });
	// const [isActive, setIsActive] = useState(false);

	const useSearchParams = () => {
		const urlParams = new URLSearchParams(window.location.search);

		return {
			getEmail: () => urlParams.get('query'),
		};
	};

	const { getEmail } = useSearchParams();

	useEffect(() => {
		const email = getEmail();
		setUserEmail(email);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// useEffect(() => {z
	// 	let intervalId;

	// 	if (isActive) {
	// 		intervalId = setInterval(() => {
	// 			const { minutes, seconds } = time;
	// 			if (minutes === 0 && seconds === 0) {
	// 				clearInterval(intervalId);
	// 				setIsActive(false);
	// 			} else {
	// 				setTime((prevTime) => {
	// 					if (prevTime.seconds === 0) {
	// 						return { minutes: prevTime.minutes - 1, seconds: 59 };
	// 					}
	// 					return { minutes: prevTime.minutes, seconds: prevTime.seconds - 1 };
	// 				});
	// 			}
	// 		}, 1000);
	// 	}

	// 	return () => clearInterval(intervalId);
	// }, [isActive, time]);

	// const startTimer = () => {
	// 	setIsActive(true);
	// };

	// const formatTime = (timeValue) => {
	// 	return timeValue < 10 ? `0${timeValue}` : `${timeValue}`;
	// };

	return (
		<>
			<Helmet>
				<link rel="canonical" href="https://hrclerks.com" />
				<title>One time password | Elevate your hiring process with HrClerks ATS</title>
				<meta
					name="description"
					content="One time password | Elevate your hiring process with HrClerks ATS – a cutting-edge Applicant Tracking System designed to simplify recruitment. Explore powerful features for seamless talent management and transform your hiring experience today."
				/>
				{/* Open Graph */}
				<meta property="article:author" content="HrClerks Talent Solution" />
				<meta property="article:publisher" content="https://www.hrclerks.com" />
				<meta property="og:site_name" content="hrclerks.com" />
				<meta
					name="og:title"
					content="One time password | Elevate your hiring process with HrClerks ATS"
				/>
				<meta
					name="og:description"
					content="One time password | Elevate your hiring process with HrClerks ATS – a cutting-edge Applicant Tracking System designed to simplify recruitment. Explore powerful features for seamless talent management and transform your hiring experience today."
				/>
				<meta name="og:image" content={openGraphImage} />
				<meta property="og:image:width" content="1200" />
				<meta property="og:image:height" content="630" />
				<meta name="og:type" content="website" />
				<meta name="og:url" content="https://www.hrclerks.com" />
				{/* Twitter */}
				<meta property="article:author" content="HrClerks Talent Solution" />
				<meta property="article:publisher" content="https://www.hrclerks.com" />
				<meta property="twitter:site_name" content="hrclerks.com" />
				<meta
					name="twitter:title"
					content="One time password | Elevate your hiring process with HrClerks ATS"
				/>
				<meta
					name="twitter:description"
					content="One time password | Elevate your hiring process with HrClerks ATS – a cutting-edge Applicant Tracking System designed to simplify recruitment. Explore powerful features for seamless talent management and transform your hiring experience today."
				/>
				<meta name="twitter:image" content={openGraphImage} />
				<meta property="twitter:image:width" content="1200" />
				<meta property="twitter:image:height" content="630" />
				<meta name="twitter:url" content="https://www.hrclerks.com" />
				<meta
					name="twitter:card"
					content="One time password | Elevate your hiring process with HrClerks ATS"
				/>
			</Helmet>

			<div className="fixed left-0 h-screen w-full md:flex">
				<BackButton />
				<LanguageBtn
					className="absolute end-5 top-5 sm:end-12 sm:top-12"
					language={localStorage.getItem('Language')}
				/>
				<img
					className="hidden h-full w-[45%] object-cover md:block"
					src={signUpConfirmation}
					alt=""
				/>
				<div className="mx-auto flex h-full w-full flex-col justify-center bg-white px-0 text-center">
					<img
						src={HrClerks}
						alt="HrClerks logo"
						className="mx-auto mb-[40px] block w-[30%] object-contain md:hidden"
					/>
					<p className="mb-[18px] text-center font-Montserrat text-[20px] font-bold  text-black md:text-xl">
						{t('Reset Your Password')}
					</p>
					<Text className="mx-auto mb-5 max-w-[433px] text-center">
						{t('An email with a verification code has been sent to you. Please check you inbox.')}
					</Text>

					{/* <Text className="mb-[48px]">
					<button
						type="button"
						onClick={startTimer}
						disabled={isActive}
						className="mr-2 inline-block font-bold text-[#1098FF]"
					>
						Resend
					</button>
					<span>
						in {formatTime(time.minutes)}:{formatTime(time.seconds)}
					</span>
				</Text> */}

					<ForgotPasswordOTPPage
						className="mx-auto mb-[24px] w-full max-w-[433px] px-5"
						email={userEmail}
					/>
				</div>
			</div>
		</>
	);
};

export default ForgotPasswordOTPPage;
