// export default PowerFullAtsFeatureSection;
import { useTranslation } from 'react-i18next';
import reviewCard from '../../assets/images/review-card.png';
import CircleTick from '../atoms/CircleTick';
import Heading from '../atoms/Heading';
import LText from '../atoms/LText';
import SectionTitle from '../atoms/SectionTitle';
import Text from '../atoms/Text';

const PowerFullAtsFeatureSection = () => {
	const { t } = useTranslation();

	return (
		<section className="container mx-auto pt-[120px]" id="features">
			<SectionTitle className="mb-[24px]">{t('Features')}</SectionTitle>
			<div className="mb-[112px] flex w-full flex-col items-center px-5">
				<Heading text={t('Powerful ATS Features:')} />
				<Heading
					className="text-center"
					text={t('Centralized Solutions For')}
					headingColorText=" Stakeholders"
					headingColorTextClass="text-[#1098FF]"
				/>

				<LText className="mt-[24px] max-w-[850px] md:px-24">
					{t('Know the key benefits that')}{' '}
					<span className="font-semibold text-[#1098FF]">{t('HrClerks')}</span>{' '}
					{t('can provide for your effortless recruitment process.')}
				</LText>
			</div>

			<div className="flex flex-col flex-wrap px-5 md:flex-row md:justify-around lg:flex-nowrap">
				<div className="flex w-full justify-center lg:w-[57%]">
					<img
						src={reviewCard}
						alt="Unified talent rating"
						className="me-10 h-full w-full ps-10 md:w-auto lg:ps-0"
					/>
				</div>
				<div className="w-full lg:w-[43%]">
					<div className="w-full">
						<div className="relative">
							<div className="outlined-number">01</div>
						</div>
						<div className="relative -top-[33px] max-w-[500px]">
							<Heading
								text={t('Unified Talent Rating')}
								className="mb-[40px] w-full whitespace-nowrap md:text-[32px]"
							/>
							<Text className="mb-[24px] leading-[30px]">
								{t(
									'Experience the power of our Unified Talent Rating system – not just a tool, but your shield against scammers and your guide to the best professionals. Tap into the collective wisdom of our community, saving time and money in your talent search'
								)}
							</Text>
							<div className="flex flex-col gap-[7px] px-[10px]">
								<div className="flex items-center gap-[15px]">
									<CircleTick className="h-[19px] w-[19px]" />
									<h5 className="mt-0 font-Montserrat text-base font-bold leading-[30px] text-[#02060C] md:text-[18px]">
										{t('Simplified rating')}
									</h5>
								</div>
								<div className="flex items-center gap-[15px]">
									<CircleTick className="h-[19px] w-[19px]" />
									<h5 className="mt-0 font-Montserrat text-base font-bold leading-[30px] text-[#02060C] md:text-[18px]">
										{t('Short and Filter')}
									</h5>
								</div>
								<div className="flex items-center gap-[15px]">
									<CircleTick className="h-[19px] w-[19px]" />
									<h5 className="mt-0 font-Montserrat text-base font-bold leading-[30px] text-[#02060C] md:text-[18px]">
										{t('Keyword Search')}
									</h5>
								</div>
								<div className="flex items-center gap-[15px]">
									<CircleTick className="h-[19px] w-[19px]" />
									<h5 className="mt-0 font-Montserrat text-base font-bold leading-[30px] text-[#02060C] md:text-[18px]">
										{t('Tag and flags')}
									</h5>
								</div>
							</div>
						</div>
						{/* <Button
							title="View Details"
							className="my-5 w-48 rounded-md border border-[#1098FF] bg-[#fff] py-3 text-base font-semibold text-[#1098FF]"
						/> */}
					</div>
				</div>
			</div>
		</section>
	);
};

export default PowerFullAtsFeatureSection;
