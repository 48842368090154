import { powerfulFeatureCardList } from '../../constants/powerfulFeatureCardList';
import PowerFulFeatureCard from '../molecules/PowerfulFeatureCard';

const PowerFulFeatureCards = () => {
	return (
		<section className="container mx-auto my-0 mt-[100px]">
			<div className="relative grid w-full transition-all ease-in-out md:grid-cols-4 lg:pe-[140px] lg:ps-[25px]">
				{powerfulFeatureCardList.map(({ number, title, description }) => (
					<PowerFulFeatureCard
						key={number}
						number={number}
						title={title}
						description={description}
					/>
				))}
			</div>
		</section>
	);
};

export default PowerFulFeatureCards;
