/* eslint-disable jsx-a11y/control-has-associated-label */
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import CopyIcon from '../../assets/svgs/CopyIcon';

const CopyLinkForm = ({ jobUrl, singleJobId, jobId }) => {
	const copyFieldRef = useRef();
	const [copyText, setCopyText] = useState('');
	const { id } = useParams();

	useEffect(() => {
		const textValue = copyFieldRef?.current?.firstChild?.value || singleJobId || jobId;
		// setCopyText(jobUrl || textValue);
		if (jobUrl) {
			setCopyText(jobUrl);
		} else {
			setCopyText(textValue);
		}
	}, [singleJobId, jobId, jobUrl]);

	const handleCopy = () => {
		navigator.clipboard.writeText(jobUrl || copyText);
		const copyBtn = document.getElementById('copyBtn');
		copyBtn.className = 'relative text-gray-500';

		const copytxt = document.getElementById('copytxt');
		copytxt.className =
			'scale-100 absolute -left-7 bottom-10 rounded-full bg-[#1e1c1c73] px-4 py-1 text-sm text-white transition-all';
		setTimeout(() => {
			copytxt.className =
				'scale-0 absolute -left-7 -bottom-10 rounded-full bg-[#1e1c1c73] px-4 py-1 text-sm text-white transition-all';
		}, 3000);
	};

	return (
		<form className="copy-link flex w-[70%] gap-[16px]" ref={copyFieldRef}>
			<input
				type="text"
				name=""
				id=""
				value={jobUrl || `${window.location.origin}/open/job/${id || singleJobId || jobId}`}
				disabled
				className="copy-link-input w-full rounded-[4px] border-[1px] border-gray-300 bg-gray-200 px-[16px] py-[8px] text-black"
			/>
			<button
				type="button"
				id="copyBtn"
				className="relative text-[#1098FF]"
				onClick={() => handleCopy()}
			>
				<p
					id="copytxt"
					className="absolute -left-7 scale-0 rounded-full bg-[#1e1c1c73] px-4 py-1 text-sm text-white transition-all duration-300"
				>
					Copied
				</p>
				<CopyIcon className="copy-link-button h-[45px] w-[40px] rounded-[4px] px-[9px] py-[8px] shadow" />
			</button>
		</form>
	);
};

export default CopyLinkForm;
