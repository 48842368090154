/* eslint-disable react/no-unescaped-entities */
import { useTranslation } from 'react-i18next';
import caenTagging from '../../assets/images/CalantTagging.png';
import Heading from '../atoms/Heading';
import Text from '../atoms/Text';

const CsvFileSection = () => {
	const { t } = useTranslation();

	return (
		<section className="relative mt-28 xl:mb-36 2xl:my-60">
			<div className="container mx-auto flex w-full flex-col gap-5 px-5 pt-10 lg:flex-row lg:justify-between">
				<div className="order-1 mx-auto flex-[50%] lg:order-none">
					{/* <img src={caenTagging} alt="" className="left-0 xl:absolute" /> */}
					<img
						src={caenTagging}
						alt="Talent Tagging"
						className="start-[30px] lg:absolute lg:top-1/2 lg:w-[55%] lg:-translate-y-[40%]"
					/>
				</div>
				<div className="flex-[50%]">
					<div className="mx-auto flex h-full w-full flex-col lg:max-w-[461px]">
						<div className="relative">
							<div className="outlined-number">11</div>
							<Heading text={t('Talent Tagging')} className="relative bottom-8 xl:text-[32px]" />
							<Text className="leading-[30px]">
								{t(
									"Easily categorize applicants with Talent Tagging. Label candidates as 'Good Fit,' 'Maybe,' 'Not Fit,' or 'Unrated' for efficient screening, reference, and sorting. Streamline your decision-making process and tailor your talent pool to meet your unique recruitment needs"
								)}
							</Text>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default CsvFileSection;
