import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsFillTelephoneFill, BsTwitter } from 'react-icons/bs';
import { FaFacebookF, FaLinkedinIn, FaYoutube } from 'react-icons/fa';
import { FiMail } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import Logo from '../../assets/svgs/Logo';
import LanguageButton from '../molecules/LanguageButton';

export const FooterContents = () => {
	const { t } = useTranslation();
	const [language, setLanguage] = useState('English');

	return (
		<section className="container mx-auto">
			<div className="my-[60px] flex justify-between gap-10 border-none border-t-[#12273727]">
				<div className="relative mb-10">
					<img src={Logo} alt="" className="cursor-pointer" />

					<LanguageButton language={language} setLanguage={setLanguage} />

					<div className="absolute bottom-0 mt-auto block">
						<h3 className="font-Montserrat text-base font-normal">{t('Follow us on')} :</h3>
						<div className="mt-4 flex gap-3">
							<FaFacebookF className="cursor-pointer text-xl hover:text-[#2BD67B]" />
							<BsTwitter className="cursor-pointer text-xl hover:text-[#2BD67B]" />
							<FaLinkedinIn className="cursor-pointer text-xl hover:text-[#2BD67B]" />
							<FaYoutube className="cursor-pointer text-xl hover:text-[#2BD67B]" />
						</div>
					</div>
				</div>
				<div className="ml-auto flex-wrap gap-[72px] sm:flex">
					<div className="font-Montserrat text-[14.4px]">
						<h4 className="font-bold">{t('Products')}</h4>
						<ul className="mt-10">
							<li className="mt-[19px] cursor-pointer font-normal hover:text-gray-300">
								<Link to="/">{t('ATS')}</Link>
							</li>
							<li className="mt-[19px] cursor-pointer font-normal hover:text-gray-300">
								<a href="http://app.hrclerks.com" target="_blank" rel="noreferrer">
									{t('Slack Application')}
								</a>
							</li>
							<li className="mt-[19px] cursor-pointer font-normal hover:text-gray-300">
								<a href="http://jobs.hrclerks.com" target="_blank" rel="noreferrer">
									{t('Job Board')}
								</a>
							</li>
						</ul>
					</div>
					<div className="font-Montserrat text-[14.4px]">
						<h4 className="font-bold">{t('Company')}</h4>
						<ul className="mt-10">
							<li className="mt-[19px] cursor-pointer font-normal hover:text-gray-300">
								<Link to="about" smooth duration={800}>
									{t('About')}
								</Link>
							</li>
							<li className="mt-[19px] cursor-pointer font-normal hover:text-gray-300">
								<Link to="features" smooth duration={800}>
									{t('Features')}
								</Link>
							</li>
							<li className="mt-[19px] cursor-pointer font-normal hover:text-gray-300">
								<a href="http://blog.hrclerks.com" target="_blank" rel="noreferrer">
									{t('Blog')}
								</a>
							</li>
							<li className="mt-[19px] cursor-pointer font-normal hover:text-gray-300">
								<Link to="pricing" smooth duration={800}>
									{t('Pricing')}
								</Link>
							</li>
							<li className="mt-[19px] cursor-pointer font-normal hover:text-gray-300">
								<Link to="testimonials" smooth duration={800}>
									{t('Testimonials')}
								</Link>
							</li>
						</ul>
					</div>
					<div className="font-Montserrat text-[14.4px]">
						<h4 className="font-bold">{t('Legal')} </h4>
						<ul className="mt-10">
							<li className="mt-[19px] cursor-pointer font-normal hover:text-gray-300">
								<Link to="/termsConditions">{t('Terms & Conditions')}</Link>
							</li>
							<li className="mt-[19px] cursor-pointer font-normal hover:text-gray-300">
								<Link to="/policyPage">{t('Privacy Policies')}</Link>
							</li>
						</ul>
					</div>
					<div className="font-Montserrat text-[14.4px]">
						<h4 className="font-bold">{t('Get in Touch')} </h4>
						<div className="mt-10">
							<div className="flex gap-4">
								<FiMail className="w-[22px] text-[30px]" />
								<div>
									<a href="mailto:contact@hrclerks.com">
										<p className="mb-3 cursor-pointer">contact@hrclerks.com</p>
									</a>
									<a href="mailto:support@hrclerks.com">
										<p className="mb-3 cursor-pointer">support@hrclerks.com</p>
									</a>
								</div>
							</div>
							<div className="mt-5 flex gap-4">
								<BsFillTelephoneFill className="w-[22px] text-[30px]" />
								<div>
									<a href="tel:+8801984743393">
										<p className="cursor-pointer">+8801984743393</p>
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<hr className="my-[30px] border border-[#12273727]" />
			<p className="pb-[30px] text-center font-Montserrat text-sm opacity-60">
				©2024 HrClerks Talent Solutions, a Concern of EWN. All rights reserved.
			</p>
		</section>
	);
};
