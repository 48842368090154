import { useTranslation } from 'react-i18next';

const PowerFulFeatureCard = ({ number, title, description }) => {
	const isLarge = window.innerWidth > 768;
	const { t } = useTranslation();

	return (
		<div
			className="box group relative right-0 z-0 mx-auto w-full max-w-[420px] rounded-[12px] border bg-white p-14 drop-shadow-2xl sm:min-w-[420px] md:w-[25vw]"
			style={{ order: !isLarge ? +number : 1 }}
		>
			<div className="relative order-1 w-full pt-[50px]">
				<div>
					<div className="relative">
						<div className="outlined-number absolute -top-[50px]" style={{ fontSize: '65px' }}>
							{t(number)}
						</div>
						<h2 className="line-clamp-2 font-['Montserrat'] text-[32px] font-bold leading-[52px] text-[#5F6D83]">
							{t(title)}
						</h2>
					</div>

					<div className="my-8 h-2 rounded-full bg-gray-300">
						<div className="my-8 h-2 w-0 rounded-full bg-[#1098FF] transition-all duration-300 group-hover:w-1/2" />
					</div>
					<p className="font-base line-clamp-8 my-5 font-Montserrat text-base leading-[30px] text-[#81838C] md:mt-0">
						{t(description)}
					</p>
				</div>
				<div className="w-36">
					{/* <Button
						title="View Details"
						className="mt-5 w-36 rounded-md border border-[#1098FF] bg-[#fff] py-3 text-base font-semibold text-[#1098FF]"
					/> */}
				</div>
			</div>
		</div>
	);
};

export default PowerFulFeatureCard;
