const CircleWriteSVG = ({
	width = '24',
	height = '25',
	className = 'text-[#1098FF]',
	style = {},
}) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox={`0 0 ${width} ${height}`}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={className}
			style={style}
		>
			<g id="arrow-left-circle-line">
				<path
					id="Path 853"
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12 2.12793C6.48 2.12793 2 6.60793 2 12.1279C2 17.6479 6.48 22.1279 12 22.1279C17.52 22.1279 22 17.6479 22 12.1279C22 6.60793 17.52 2.12793 12 2.12793ZM12 20.1279C7.58 20.1279 4 16.5479 4 12.1279C4 7.70793 7.58 4.12793 12 4.12793C16.42 4.12793 20 7.70793 20 12.1279C20 16.5479 16.42 20.1279 12 20.1279ZM8 11.1279H12V8.12793L16 12.1279L12 16.1279V13.1279H8V11.1279Z"
					fill="currentColor"
				/>
			</g>
		</svg>
	);
};

export default CircleWriteSVG;
