import EwnSVG from '../../assets/svgs/EwnSVG';
import ItmoomSVG from '../../assets/svgs/ItmoomSVG';
import LimoSVG from '../../assets/svgs/LimoSVG';
import MasaratSVG from '../../assets/svgs/MasaratSVG';
import ReviewCard from '../molecules/ReviewCard';

const ClientReviewSection = () => {
	return (
		<section
			className="flex h-full  flex-col items-center justify-center bg-[#d7e7f7ba] p-5 md:h-[100vh]"
			id="testimonials"
		>
			<div className="container mx-auto flex flex-col items-center gap-[64px]">
				<ReviewCard />

				<div className="mx-auto flex w-full flex-nowrap items-center justify-center gap-5 sm:gap-[88px]">
					<MasaratSVG />
					<ItmoomSVG />
					<LimoSVG />
					<EwnSVG />
				</div>
			</div>
		</section>
	);
};

export default ClientReviewSection;
