import { Helmet } from 'react-helmet-async';

import HrClerks from '../assets/images/HrClerks.png';
import openGraphImage from '../assets/images/open-geaph-image.png';
import signUpConfirmation from '../assets/images/signUpConfirmation.png';
import BackButton from '../components/atoms/BackButton';
import NewPasswordStep from '../components/templates/NewPasswordStep';

const NewPasswordPage = () => {
	const searchParams = new URLSearchParams(window.location.search);

	const token = searchParams.get('query');
	// const email = searchParams.get('email');

	return (
		<>
			<Helmet>
				<link rel="canonical" href="https://hrclerks.com" />
				<title>Set New Password | Elevate your hiring process with HrClerks ATS</title>
				<meta
					name="description"
					content="Set New Password | Elevate your hiring process with HrClerks ATS – a cutting-edge Applicant Tracking System designed to simplify recruitment. Explore powerful features for seamless talent management and transform your hiring experience today."
				/>
				{/* Open Graph */}
				<meta property="article:author" content="HrClerks Talent Solution" />
				<meta property="article:publisher" content="https://www.hrclerks.com" />
				<meta property="og:site_name" content="hrclerks.com" />
				<meta
					name="og:title"
					content="Set New Password | Elevate your hiring process with HrClerks ATS"
				/>
				<meta
					name="og:description"
					content="Set New Password | Elevate your hiring process with HrClerks ATS – a cutting-edge Applicant Tracking System designed to simplify recruitment. Explore powerful features for seamless talent management and transform your hiring experience today."
				/>
				<meta name="og:image" content={openGraphImage} />
				<meta property="og:image:width" content="1200" />
				<meta property="og:image:height" content="630" />
				<meta name="og:type" content="website" />
				<meta name="og:url" content="https://www.hrclerks.com" />
				{/* Twitter */}
				<meta property="article:author" content="HrClerks Talent Solution" />
				<meta property="article:publisher" content="https://www.hrclerks.com" />
				<meta property="twitter:site_name" content="hrclerks.com" />
				<meta
					name="twitter:title"
					content="Set New Password | Elevate your hiring process with HrClerks ATS"
				/>
				<meta
					name="twitter:description"
					content="Set New Password | Elevate your hiring process with HrClerks ATS – a cutting-edge Applicant Tracking System designed to simplify recruitment. Explore powerful features for seamless talent management and transform your hiring experience today."
				/>
				<meta name="twitter:image" content={openGraphImage} />
				<meta property="twitter:image:width" content="1200" />
				<meta property="twitter:image:height" content="630" />
				<meta name="twitter:url" content="https://www.hrclerks.com" />
				<meta
					name="twitter:card"
					content="Set New Password | Elevate your hiring process with HrClerks ATS"
				/>
			</Helmet>

			<div className="fixed left-0 h-screen w-full md:flex">
				<BackButton />
				<img
					className="hidden h-full w-[45%] object-cover md:block"
					src={signUpConfirmation}
					alt=""
				/>
				<div className="mx-auto flex h-full w-full flex-col justify-center bg-white px-5 text-center">
					<img
						src={HrClerks}
						alt="HrClerks logo"
						className="mx-auto mb-[40px] block w-[30%] object-contain md:hidden"
					/>
					<NewPasswordStep token={token} className="mb-[24px]" />
				</div>
			</div>
		</>
	);
};

export default NewPasswordPage;
