const InputDownSVG = ({ width = '14', height = '9', className = 'text-[#24282D]', style = {} }) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox={`0 0 ${width} ${height}`}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={className}
			style={style}
		>
			<path
				d="M2.22168 1.78027L7.40144 6.96003L12.5812 1.78027"
				stroke="currentColor"
				strokeWidth="1.84991"
				strokeLinecap="square"
			/>
		</svg>
	);
};

export default InputDownSVG;
