/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-unused-vars */
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import Button from '../atoms/Button';
import Heading from '../atoms/Heading';
import SubscriptionPlans from '../molecules/SubscriptionPlans';
import PricingPlanPerYear from './PricingPlanPerYear';

const PricingPlanSection = ({ setIsOpen }) => {
	const [pricing, setPricing] = useState('Per monthly');
	const location = useLocation();
	const { t } = useTranslation();

	return (
		<section
			className={` ${location.pathname !== '/' ? 'bg-white py-5' : 'bg-[#f0f4fc] py-20'}`}
			id="pricing"
		>
			<div className="mx-auto">
				<div className="flex flex-col items-center">
					<h5
						className={`text-sm font-bold uppercase text-[#1098FF] ${
							location.pathname !== '/' && 'hidden'
						}`}
					>
						{t('Pricing')}
					</h5>

					<Heading text={t('Pricing & Plans')} className="mb-2 sm:my-4" />

					<div className="mx-auto my-5 flex w-full max-w-[340px] gap-2 md:mb-10">
						<Button
							title={t('Per monthly')}
							onClick={() => setPricing('Per monthly')}
							className={`w-full rounded-full py-2.5 text-center text-sm font-bold uppercase hover:bg-[#1098FF] hover:text-white ${
								pricing === 'Per monthly' ? 'bg-[#1098FF] text-white' : 'bg-transparent text-black'
							}`}
						/>
						<Button
							title={t('Per yearly')}
							onClick={() => setPricing('Per yearly')}
							className={`w-full rounded-full py-2.5 text-center text-sm font-bold uppercase hover:bg-[#1098FF] hover:text-white ${
								pricing === 'Per yearly' ? 'bg-[#1098FF] text-white' : 'bg-transparent text-black'
							}`}
						/>
					</div>
				</div>
				{pricing === 'Per monthly' ? (
					<SubscriptionPlans setIsOpen={setIsOpen} />
				) : (
					<PricingPlanPerYear setIsOpen={setIsOpen} />
				)}
			</div>
		</section>
	);
};

export default PricingPlanSection;
