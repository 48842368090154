import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';

import openGraphImage from '../../assets/images/open-geaph-image.png';
import Footer from '../../components/molecules/Footer';
import Navbar from '../../components/molecules/Navbar';
import CareerSection from '../../components/templates/CareerSection';
import ClientReviewSection from '../../components/templates/ClientReviewSection';
import CsvFileSection from '../../components/templates/CsvFileSection';
import HeroSection from '../../components/templates/HeroSection';
import JobPostSection from '../../components/templates/JobPostSection';
import PowerFulFeatureCards from '../../components/templates/PowerFulFeatureCards';
import PowerFullAtsFeatureSection from '../../components/templates/PowerFullAtsFeatureSection';
import PricingPlanSection from '../../components/templates/PricingPlanSection';
import SortTalentsSection from '../../components/templates/SortTalentsSection';
import TalentFeatureSection from '../../components/templates/TalentFeatureSection';
import TalentTaggingSection from '../../components/templates/TalentTaggingSection';
// import useAuth from '../../hooks/useAuth';

const HomePage = () => {
	const location = useLocation();
	const [language, setLanguage] = useState('English');
	const [activeSection, setActiveSection] = useState('home');

	useEffect(() => {
		const handleScroll = () => {
			const sections = document.querySelectorAll('section');

			sections.forEach((section) => {
				const sectionTop = section.offsetTop;
				const sectionHeight = section.clientHeight;

				if (window.scrollY >= sectionTop - sectionHeight / 3) {
					setActiveSection(section.id);
				}
			});
		};

		// Attach the scroll event listener
		window.addEventListener('scroll', handleScroll);

		// Clean up the event listener on component unmount
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	return (
		<>
			<Helmet>
				<link rel="canonical" href="https://hrclerks.com" />
				<title>HrClerks - Applicant Tracking Software for Effortless Hiring</title>
				<meta
					name="description"
					content="Post jobs, get candidates and manage applicants all in one place. Get started today!"
				/>
				{/* Open Graph */}
				<meta property="article:author" content="HrClerks Talent Solution" />
				<meta property="article:publisher" content="https://www.hrclerks.com" />
				<meta property="og:site_name" content="hrclerks.com" />
				<meta
					name="og:title"
					content="HrClerks: Applicant Tracking Software for Effortless Hiring"
				/>
				<meta
					name="og:description"
					content="Elevate your hiring process with HrClerks ATS – a cutting-edge Applicant Tracking System designed to simplify recruitment. Explore powerful features for seamless talent management and transform your hiring experience today."
				/>
				<meta name="og:image" content={openGraphImage} />
				<meta property="og:image:width" content="1200" />
				<meta property="og:image:height" content="630" />
				<meta name="og:type" content="website" />
				<meta name="og:url" content="https://www.hrclerks.com" />
				{/* Twitter */}
				<meta property="article:author" content="HrClerks Talent Solution" />
				<meta property="article:publisher" content="https://www.hrclerks.com" />
				<meta property="twitter:site_name" content="hrclerks.com" />
				<meta name="twitter:title" content="Elevate your hiring process with HrClerks ATS" />
				<meta
					name="twitter:description"
					content="Elevate your hiring process with HrClerks ATS – a cutting-edge Applicant Tracking System designed to simplify recruitment. Explore powerful features for seamless talent management and transform your hiring experience today."
				/>
				<meta name="twitter:image" content={openGraphImage} />
				<meta property="twitter:image:width" content="1200" />
				<meta property="twitter:image:height" content="630" />
				<meta name="twitter:url" content="https://www.hrclerks.com" />
				<meta name="twitter:card" content="Elevate your hiring process with HrClerks ATS" />
			</Helmet>

			{location.pathname === '/' ? (
				<Navbar activeSection={activeSection} language={language} setLanguage={setLanguage} />
			) : null}

			<HeroSection />
			<ClientReviewSection />
			<JobPostSection />
			{/* <ScheduleDemo /> */}
			<SortTalentsSection />
			<PowerFullAtsFeatureSection />
			<PowerFulFeatureCards />
			<TalentTaggingSection />
			<TalentFeatureSection />
			<CsvFileSection />
			<CareerSection />
			<PricingPlanSection />
			<Footer language={language} setLanguage={setLanguage} />
		</>
	);
};

export default HomePage;
