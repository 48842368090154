export const powerfulFeatureCardList = [
	{
		number: '05',
		title: 'Job Board Integration',
		description: `Maximize your reach with our Job Board Integration. Showcase your posted jobs alongside the ATS, leveraging our inbound traffic to drive more qualified applicants. Seamlessly connect with the right talent pool and elevate your recruitment strategy to new heights.`,
		link: '/',
	},
	{
		number: '04',
		title: 'Talents Management',
		description: `Effortlessly manage talents with HrClerks ATS. Streamlining recruitment for enhanced efficiency, our platform ensures a seamless experience for applicants. Utilize advanced keyword search, sorting, and filtering tools, making job posting and talent management a breezes`,
		link: '/',
	},
	{
		number: '03',
		title: 'Easiest Spam Control',
		description: `Say goodbye to spam headaches with our Easiest Spam Control feature. Tag and flag spams effortlessly, ensuring a clean and genuine recruitment process. No more drowning in fake responses or dealing with fraudulent applicants – just smooth, hassle-free hiring.`,
		link: '/',
	},
	{
		number: '02',
		title: 'Centralized Hiring Pipeline',
		description: `Unlock endless possibilities with our Centralized Hiring Pipeline. Seamlessly tap into diverse channels, from social media to unconventional networks, all from one centralized hub. Elevate your recruitment game with unparalleled reach and efficiency.`,
		link: '/',
	},
];
