/* eslint-disable react/no-unescaped-entities */
import { useTranslation } from 'react-i18next';
import reviewer from '../../assets/images/Reviewer.png';
import DubbleCommaSvg from '../../assets/svgs/DubbleCommaSvg';

const ReviewCard = () => {
	const { t } = useTranslation();

	return (
		<div className="relative mx-[20px] rounded-[20px] bg-white px-14 py-11 drop-shadow-2xl md:mx-auto lg:w-[875px]">
			<div className="flex flex-col gap-8 md:flex-row md:justify-start">
				<div className="h-15 w-15 mt-[30px] rounded-full">
					<img src={reviewer} alt="Review Form CEO" />
				</div>
				<div className="flex-1">
					<div className="flex">
						<p className="mt-[30px] max-w-[562px] font-semibold leading-[30px] text-[#687497] md:text-[20px]">
							{t('Revolutionizing recruitment with')}{' '}
							<span className="font-semibold text-[#1098FF]">HrClerks</span>{' '}
							{t("ATS – where simplicity meets innovation. Let's build successful teams together")}.
						</p>
					</div>
					<div className="flex justify-between">
						<div className="mt-[40px]">
							<p className="mb-[10px] text-base font-semibold text-[#687497] md:text-[18px]">
								{t('Nabila Afrose')}
							</p>
							<p className="text-sm font-medium text-[#687497] md:text-base">
								{t('CEO, HrClerks Talent Solutions')}
							</p>
						</div>
						<DubbleCommaSvg className="absolute bottom-0 end-0 h-full w-full" />
					</div>
				</div>
			</div>
		</div>
	);
};

export default ReviewCard;
