const ItmoomSVG = () => {
	return (
		<svg width="92" height="90" viewBox="0 0 92 90" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M43.5468 66.8385C43.0004 66.8385 42.4436 66.8182 41.8867 66.7676C36.6334 66.3017 31.9264 63.6378 28.6483 59.2824C25.4753 55.0687 24.0254 49.7308 24.5507 44.2714C25.076 38.8119 27.5241 33.8285 31.4536 30.253C35.5092 26.5458 40.6364 24.7632 45.8897 25.2392C51.1431 25.7051 55.8501 28.369 59.1282 32.7245C62.3012 36.9381 63.7616 42.276 63.2363 47.7354C62.7109 53.1949 60.2629 58.1783 56.3334 61.7639C52.7086 65.0659 48.2117 66.8385 43.5468 66.8385ZM44.2612 32.2484C41.4664 32.2484 38.7452 33.3423 36.5073 35.3884C33.8806 37.7889 32.2311 41.1719 31.8738 44.9196C31.5166 48.6673 32.4832 52.2833 34.6056 55.1093C36.6229 57.7934 39.4492 59.4242 42.5696 59.6976C45.7006 59.9813 48.7791 58.8772 51.2797 56.5982C53.9063 54.1977 55.5559 50.8146 55.9131 47.0669C56.2703 43.3193 55.3037 39.7032 53.1814 36.8773C51.1641 34.1931 48.3378 32.5624 45.2173 32.2889C44.8916 32.2687 44.5764 32.2484 44.2612 32.2484Z"
				fill="#3ABBD5"
			/>
			<path
				d="M29.4571 58.6342C29.436 58.6342 29.4045 58.6342 29.3835 58.6342L22.6803 58.5025C14.2749 58.2999 7.59268 52.1922 7.71876 44.8791L7.41406 24.986L7.42457 24.6619C7.55065 19.1214 7.68724 12.8314 14.7792 9.47875C15.8929 8.95205 17.1222 8.55702 18.5406 8.28354L28.3749 6.34893C30.3606 5.9539 32.3044 7.18962 32.7141 9.11411C33.1239 11.0285 31.8316 12.9023 29.8458 13.2973L20.0116 15.2319C19.2236 15.3839 18.5616 15.5865 18.0153 15.8498C15.1785 17.1969 14.8948 19.1417 14.7687 24.824V25.0164L15.0734 44.9095V44.9703C14.9894 48.4141 18.4881 51.311 22.8484 51.4224L29.5306 51.5541C31.5584 51.5946 33.1764 53.2152 33.1344 55.1701C33.0924 57.0946 31.4533 58.6342 29.4571 58.6342Z"
				fill="#3ABBD5"
			/>
			<path
				d="M40.825 42.5701C40.2786 42.5701 39.7218 42.5498 39.1649 42.4992C28.5006 41.5471 20.7257 31.4486 21.8289 20.003C22.9321 8.5472 32.5142 0.00855857 43.168 0.960673C48.4213 1.4266 53.1283 4.09049 56.4064 8.44591C59.5794 12.6595 61.0398 17.9974 60.5145 23.4569C59.9891 28.9164 57.5411 33.8998 53.6116 37.4854C49.9868 40.7975 45.5004 42.5701 40.825 42.5701ZM41.5394 7.97998C35.2985 7.97998 29.8455 13.4597 29.1521 20.6512C28.4271 28.2073 33.2286 34.8418 39.8478 35.4292C42.9683 35.7027 46.0573 34.6088 48.5579 32.3298C51.1845 29.9293 52.8341 26.5462 53.1913 22.7985C53.5485 19.0508 52.5819 15.4348 50.4596 12.6089C48.4423 9.92473 45.616 8.29398 42.4955 8.0205C42.1698 7.99011 41.8546 7.97998 41.5394 7.97998Z"
				fill="#575756"
			/>
			<path
				d="M43.3047 66.7072C42.7688 66.7072 42.2225 66.6869 41.6761 66.6363C39.6588 66.454 38.1669 64.732 38.356 62.7772C38.5451 60.8223 40.3523 59.4043 42.3591 59.5765C48.9993 60.1741 54.9671 54.5019 55.692 46.9458C56.0492 43.1981 55.0826 39.5821 52.9603 36.7561C50.943 34.072 48.1167 32.4412 44.9962 32.1677C42.9789 31.9854 41.487 30.2635 41.6761 28.3086C41.8652 26.3538 43.6724 24.9357 45.6792 25.1079C50.9325 25.5738 55.6395 28.2377 58.9176 32.5931C62.0906 36.8067 63.551 42.1447 63.0257 47.6041C61.975 58.4623 53.307 66.7072 43.3047 66.7072Z"
				fill="#3ABBD5"
			/>
			<path
				d="M65.7377 59.4151C65.6852 59.4151 65.6432 59.4151 65.5906 59.4151L56.3132 59.324C54.2855 59.3037 52.6569 57.7034 52.6779 55.7384C52.6989 53.7936 54.338 52.2338 56.3553 52.2338C56.3658 52.2338 56.3763 52.2338 56.3973 52.2338L65.6747 52.3249C67.4398 52.3553 69.1314 51.6969 70.4027 50.4916C71.674 49.2964 72.3779 47.6859 72.399 45.9741L72.6931 20.2873C72.7142 18.3426 74.3532 16.7827 76.3705 16.7827C76.381 16.7827 76.402 16.7827 76.4125 16.7827C78.4403 16.803 80.0688 18.4135 80.0478 20.3683L79.7536 46.045C79.7116 49.6509 78.2302 53.0238 75.551 55.5459C72.9033 58.0477 69.4256 59.4151 65.7377 59.4151Z"
				fill="#3ABBD5"
			/>
			<path
				d="M4.99845 54.4721C4.97743 54.4721 4.94591 54.4721 4.9249 54.4721C2.89711 54.4316 1.27909 52.8109 1.32111 50.8561L2.00405 17.9271C2.17215 9.96582 9.92607 3.53399 20.044 2.96677L20.2331 2.95664L34.1229 2.88574C34.1334 2.88574 34.1334 2.88574 34.1439 2.88574C36.1612 2.88574 37.8108 4.45572 37.8213 6.41059C37.8318 8.36546 36.1927 9.96582 34.165 9.97595L20.3802 10.0469C14.3809 10.4115 9.44277 13.9971 9.35871 18.0588L8.67578 50.9979C8.63375 52.9325 6.99471 54.4721 4.99845 54.4721Z"
				fill="#575756"
			/>
			<path
				d="M69.7826 10.7659C72.1791 10.7659 74.1219 8.89297 74.1219 6.58264C74.1219 4.27231 72.1791 2.39941 69.7826 2.39941C67.3861 2.39941 65.4434 4.27231 65.4434 6.58264C65.4434 8.89297 67.3861 10.7659 69.7826 10.7659Z"
				fill="#3ABBD5"
			/>
			<path
				d="M74.4685 14.1696C76.424 14.1696 78.0092 12.6414 78.0092 10.7562C78.0092 8.87102 76.424 7.34277 74.4685 7.34277C72.513 7.34277 70.9277 8.87102 70.9277 10.7562C70.9277 12.6414 72.513 14.1696 74.4685 14.1696Z"
				fill="#575756"
			/>
			<path
				d="M86.3516 50.3278C86.3306 50.3278 86.3096 50.3278 86.2991 50.3278C84.2713 50.2975 82.6428 48.687 82.6743 46.7321L83.3047 7.10794C83.3362 5.15307 84.9752 3.56284 87.0345 3.61348C89.0623 3.64387 90.6909 5.25436 90.6593 7.20923L90.0289 46.8334C89.9974 48.7781 88.3584 50.3278 86.3516 50.3278Z"
				fill="#3ABBD5"
			/>
			<path
				d="M3.7904 67.4971V70.0394C3.7904 70.8801 3.3281 71.3055 2.39301 71.3055C1.44741 71.3055 0.974609 70.8801 0.974609 70.0394V67.4971C0.974609 66.6564 1.44741 66.231 2.39301 66.231C3.3176 66.231 3.7904 66.6564 3.7904 67.4971ZM3.7904 75.1241V87.8358C3.7904 88.6866 3.3281 89.1121 2.39301 89.1121C1.44741 89.1121 0.974609 88.6866 0.974609 87.8358V75.1241C0.974609 74.2834 1.44741 73.858 2.39301 73.858C3.3176 73.858 3.7904 74.2733 3.7904 75.1241Z"
				fill="#3ABBD5"
				stroke="#3ABBD5"
				strokeMiterlimit="10"
			/>
			<path
				d="M10.1993 73.8567V67.5059C10.1993 66.6652 10.6721 66.2397 11.6177 66.2397C12.5528 66.2397 13.0151 66.6652 13.0151 67.5059V73.8567H14.4125C15.3581 73.8567 15.8309 74.2821 15.8309 75.1228C15.8309 75.9736 15.3581 76.399 14.4125 76.399H13.0151V85.282C13.0151 86.1025 13.2673 86.8419 13.7821 87.5003C14.2864 88.1586 14.9694 88.6144 15.8309 88.8778C15.3581 89.0297 14.8853 89.1006 14.4125 89.1006C13.2358 89.1006 12.2376 88.736 11.4181 87.9966C10.6091 87.247 10.1993 86.3354 10.1993 85.282V76.399H8.79145C7.84585 76.399 7.37305 75.9736 7.37305 75.1228C7.37305 74.2821 7.84585 73.8567 8.79145 73.8567H10.1993Z"
				fill="#3ABBD5"
				stroke="#3ABBD5"
				strokeMiterlimit="10"
			/>
			<path
				d="M19.0362 77.6664C19.0362 76.8763 18.7841 76.147 18.2692 75.4785C17.7544 74.81 17.082 74.3441 16.252 74.0807C16.6932 73.9288 17.1555 73.8579 17.6493 73.8579C18.9312 73.8579 19.9923 74.3137 20.8434 75.2152C21.9256 74.3137 23.1969 73.8579 24.6573 73.8579C26.3384 73.8579 27.7462 74.4353 28.8705 75.5798C28.902 75.5494 28.9755 75.4684 29.1226 75.3469C30.2153 74.3542 31.5287 73.8579 33.0941 73.8579C34.6491 73.8579 35.973 74.3542 37.0762 75.3469C38.1689 76.3192 38.7257 77.5144 38.7257 78.9325V87.8357C38.7257 88.6866 38.2529 89.112 37.3178 89.112C36.3827 89.112 35.9204 88.6866 35.9204 87.8357V78.9325C35.9204 78.2133 35.6473 77.6157 35.1009 77.1295C34.5651 76.6433 33.8927 76.4003 33.1152 76.4003C32.3272 76.4003 31.6652 76.6433 31.1294 77.1295C30.5936 77.6157 30.3204 78.2133 30.3204 78.9325V87.775H30.3099C30.3204 87.7851 30.3204 87.8054 30.3204 87.8357C30.3204 88.0586 30.2468 88.2814 30.1103 88.4941C29.8371 88.9094 29.4378 89.112 28.902 89.112C27.9669 89.112 27.5046 88.6866 27.5046 87.8357V78.9325C27.5046 78.2133 27.2314 77.6157 26.6851 77.1295C26.1492 76.6433 25.4873 76.4003 24.6993 76.4003C23.9218 76.4003 23.2494 76.6433 22.6925 77.1295C22.1567 77.6157 21.8835 78.2133 21.8835 78.9325V87.8357C21.8835 88.6866 21.4212 89.112 20.4861 89.112C19.5405 89.112 19.0677 88.6866 19.0677 87.8357V77.6664H19.0362Z"
				fill="#3ABBD5"
				stroke="#3ABBD5"
				strokeMiterlimit="10"
			/>
			<path
				d="M53.6035 85.2841C53.6035 86.1147 53.8556 86.8541 54.3599 87.5024C54.8643 88.1607 55.5472 88.6165 56.4087 88.8799C55.9359 89.0318 55.4631 89.1027 55.0114 89.1027C53.7085 89.1027 52.6474 88.6469 51.7963 87.7353C50.6931 88.6469 49.4218 89.1027 47.9824 89.1027C46.4274 89.1027 45.0931 88.6064 43.9899 87.6239C42.8972 86.6211 42.3613 85.4158 42.3613 84.0079V78.9232C42.3613 77.5052 42.9077 76.3099 43.9899 75.3274C45.0931 74.3449 46.4274 73.8486 47.9824 73.8486C49.5374 73.8486 50.8612 74.3449 51.9644 75.3376C53.0571 76.31 53.614 77.5052 53.614 78.9232V85.2841H53.6035ZM45.9756 77.1304C45.4398 77.6166 45.1666 78.2142 45.1666 78.9333V84.018C45.1666 84.7372 45.4398 85.3449 45.9756 85.8311C46.5115 86.3173 47.1839 86.5604 47.9824 86.5604C48.7704 86.5604 49.4323 86.3173 49.9682 85.8311C50.5145 85.3348 50.7877 84.727 50.7877 84.018V78.9333C50.7877 78.2142 50.5145 77.6166 49.9682 77.1304C49.4323 76.6442 48.7704 76.4011 47.9824 76.4011C47.2049 76.4011 46.5325 76.6442 45.9756 77.1304Z"
				fill="#3ABBD5"
				stroke="#3ABBD5"
				strokeMiterlimit="10"
			/>
			<path
				d="M68.4062 85.2841C68.4062 86.1147 68.6584 86.8541 69.1627 87.5024C69.667 88.1607 70.3499 88.6165 71.2115 88.8799C70.7387 89.0318 70.2659 89.1027 69.8141 89.1027C68.5113 89.1027 67.4501 88.6469 66.5991 87.7353C65.4959 88.6469 64.2245 89.1027 62.7851 89.1027C61.2301 89.1027 59.8958 88.6064 58.7926 87.6239C57.6999 86.6211 57.1641 85.4158 57.1641 84.0079V78.9232C57.1641 77.5052 57.7104 76.3099 58.7926 75.3274C59.8958 74.3449 61.2301 73.8486 62.7851 73.8486C64.3401 73.8486 65.664 74.3449 66.7672 75.3376C67.8599 76.31 68.4167 77.5052 68.4167 78.9232V85.2841H68.4062ZM60.7784 77.1304C60.2425 77.6166 59.9693 78.2142 59.9693 78.9333V84.018C59.9693 84.7372 60.2425 85.3449 60.7784 85.8311C61.3142 86.3173 61.9866 86.5604 62.7851 86.5604C63.5731 86.5604 64.2351 86.3173 64.7709 85.8311C65.3172 85.3348 65.5904 84.727 65.5904 84.018V78.9333C65.5904 78.2142 65.3172 77.6166 64.7709 77.1304C64.2351 76.6442 63.5731 76.4011 62.7851 76.4011C62.0076 76.4011 61.3352 76.6442 60.7784 77.1304Z"
				fill="#3ABBD5"
				stroke="#3ABBD5"
				strokeMiterlimit="10"
			/>
			<path
				d="M71.9679 77.6664C71.9679 76.8763 71.7157 76.147 71.2009 75.4785C70.686 74.81 70.0136 74.3441 69.1836 74.0807C69.6249 73.9288 70.0872 73.8579 70.581 73.8579C71.8628 73.8579 72.924 74.3137 73.775 75.2152C74.8572 74.3137 76.1285 73.8579 77.5889 73.8579C79.27 73.8579 80.6779 74.4353 81.8021 75.5798C81.8336 75.5494 81.9072 75.4684 82.0543 75.3469C83.147 74.3542 84.4603 73.8579 86.0258 73.8579C87.5808 73.8579 88.9046 74.3542 90.0078 75.3469C91.1005 76.3192 91.6574 77.5144 91.6574 78.9325V87.8357C91.6574 88.6866 91.1846 89.112 90.2495 89.112C89.3144 89.112 88.8521 88.6866 88.8521 87.8357V78.9325C88.8521 78.2133 88.5789 77.6157 88.0326 77.1295C87.4967 76.6433 86.8243 76.4003 86.0468 76.4003C85.2588 76.4003 84.5969 76.6433 84.061 77.1295C83.5252 77.6157 83.252 78.2133 83.252 78.9325V87.775H83.2415C83.252 87.7851 83.252 87.8054 83.252 87.8357C83.252 88.0586 83.1785 88.2814 83.0419 88.4941C82.7687 88.9094 82.3695 89.112 81.8336 89.112C80.8985 89.112 80.4362 88.6866 80.4362 87.8357V78.9325C80.4362 78.2133 80.1631 77.6157 79.6167 77.1295C79.0809 76.6433 78.419 76.4003 77.631 76.4003C76.8535 76.4003 76.181 76.6433 75.6242 77.1295C75.0883 77.6157 74.8152 78.2133 74.8152 78.9325V87.8357C74.8152 88.6866 74.3529 89.112 73.4178 89.112C72.4722 89.112 71.9994 88.6866 71.9994 87.8357V77.6664H71.9679Z"
				fill="#3C3C3B"
				stroke="#3C3C3B"
				strokeWidth="0.25"
				strokeMiterlimit="10"
			/>
			<path
				d="M3.57872 67.2949V69.8373C3.57872 70.678 3.11642 71.1034 2.18133 71.1034C1.23573 71.1034 0.773438 70.678 0.773438 69.8373V67.2949C0.773438 66.4542 1.24624 66.0288 2.18133 66.0288C3.10592 66.0288 3.57872 66.4542 3.57872 67.2949Z"
				fill="#3C3C3B"
			/>
			<path
				d="M2.17151 71.6099C0.942228 71.6099 0.238281 70.9718 0.238281 69.8374V67.295C0.238281 66.1707 0.942228 65.5225 2.17151 65.5225C3.39028 65.5225 4.09423 66.1707 4.09423 67.295V69.8374C4.10474 70.9718 3.40079 71.6099 2.17151 71.6099ZM2.17151 66.5353C1.43604 66.5353 1.28895 66.819 1.28895 67.295V69.8374C1.28895 70.3134 1.43604 70.597 2.17151 70.597C2.90698 70.597 3.04356 70.3134 3.04356 69.8374V67.295C3.05407 66.819 2.90698 66.5353 2.17151 66.5353Z"
				fill="#3C3C3B"
			/>
			<path
				d="M89.2823 54.7951C87.9164 54.7951 86.8763 54.8255 86.1408 54.8863C85.6155 54.9369 85.2372 55.0483 84.9956 55.2104C84.6594 55.413 84.4597 55.7573 84.3757 56.2233C84.3232 56.5474 84.3232 56.9525 84.3757 57.4185L84.4492 57.9553H91.205V61.2573H80.2676V57.9654H81.5914C81.5494 57.5299 81.5389 57.0437 81.5389 56.517C81.5389 55.7168 81.644 54.9977 81.8436 54.3697C82.0957 53.61 82.4845 53.0327 83.0203 52.6376C83.5141 52.2426 84.1866 51.9793 85.0376 51.8273C85.7416 51.7058 86.8343 51.645 88.3157 51.645L89.2823 54.7951Z"
				fill="#575756"
			/>
		</svg>
	);
};

export default ItmoomSVG;
