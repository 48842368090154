export const SELECT_COUNTRY = ['Saudi Arabia', 'Bangladesh', 'Indonesia', 'Pakistan'];
export const NO_OF_EMPLOYEES = ['1-49', '50-99', '100-199', '200-500'];

export const SELECT_INDUSTRY = [
	'Information Technology',
	'Financial Services',
	'Education',
	'Health care',
	'Retail',
	'Manufacturing',
	'Construction',
	'Hospitality',
	'Real Estate',
	'Transportation',
	'Media',
	'Entertainment',
	'Energy',
	'Telecommunication',
	'Automotive',
	'Agriculture',
	'Food',
	'Pharmaceutical',
	'Biotechnology',
	'Chemical',
	'Mining',
	'Aerospace',
	'Defense',
	'Shipbuilding',
	'Textile',
	'Tobacco',
	'Steel',
	'Cement',
	'Software',
	'Engineering',
	'Electronics',
	'Insurance',
	'Banking',
	'Investment',
	'Stock market',
	'Venture capital',
];

export const noOfEmployee = ['0 - 25', '26 - 50', '50 - 250', '251 - ...'];
