/* eslint-disable no-unused-vars */
import { useMutation, useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { HiDotsVertical } from 'react-icons/hi';
import { useNavigate, useParams } from 'react-router-dom';

import { deleteJob, getOpenSingleJobByCompany } from '../apis/jobs/jobApis';
import logo from '../assets/images/Logo.png';
import openGraphImage from '../assets/images/open-geaph-image.png';
import RichtextEditorField from '../components/_common/forms/RichtextEditorField';
import Button from '../components/atoms/Button';
import CustomDropdown from '../components/atoms/CustomDropdown';
import FallbackLoader from '../components/atoms/FallbackLoader';
import JobCardPill from '../components/atoms/JobCardPill';
import MText from '../components/atoms/MText';
import Text from '../components/atoms/Text';
import ConformationModal from '../components/molecules/ConfirmationModal';
import CongratulationModal from '../components/molecules/CongratulationModal';
import JobCardData from '../components/molecules/JobCardData';
import ApplyJobModal from '../components/organisms/ApplyJobModal';
import useAuth from '../hooks/useAuth';
import stripHTMLTags from '../utils/stripHTMLTags';

const PublicJobDetailsCompanyPage = () => {
	const [applicationModal, setApplicationModal] = useState(false);
	const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
	const [deleteID, setDeleteID] = useState('');
	const [toggleCongratsModal, setToggleCongratsModal] = useState(false);

	const param = useParams();

	const { t } = useTranslation();
	const { id } = useParams();
	const navigateTo = useNavigate();
	const { state: hasAccessToken } = useAuth();

	const { data, isLoading } = useQuery({
		queryKey: ['open-job', id],
		queryFn: () => getOpenSingleJobByCompany(param),
	});

	const deleteMutation = useMutation({ mutationFn: deleteJob });

	const handleConfirmationModal = (jobID) => {
		setDeleteID(jobID);
		setIsConfirmationModalOpen(!isConfirmationModalOpen);
	};

	const handleShareJobModal = () => {
		setToggleCongratsModal(true);
	};

	const handleJobDelete = async () => {
		await deleteMutation?.mutateAsync(deleteID);
		handleConfirmationModal(false);
		navigateTo('/jobs');
	};

	if (isLoading) return <FallbackLoader />;

	return (
		<>
			<Helmet>
				<link rel="canonical" href={data?.data?.job?.applyUrl} />
				<title>{`${data?.data?.job?.title} | ${data?.data?.job?.company?.name}`}</title>
				<meta
					name="description"
					content={stripHTMLTags(data?.data?.job?.description?.split('.')[0])}
				/>
				<meta name="keywords" content={data?.data?.job?.tags?.map((tag) => tag?.name).join(', ')} />
				{/* Open Graph */}
				<meta property="article:author" content="HrClerks Talent Solution" />
				<meta property="article:publisher" content="https://www.hrclerks.com" />
				<meta property="og:site_name" content="hrclerks.com" />
				<meta
					name="og:title"
					content={`${data?.data?.job?.title} | Elevate your hiring process with HrClerks ATS`}
				/>
				<meta
					name="og:description"
					content={stripHTMLTags(data?.data?.job?.description?.split('.')[0])}
				/>
				<meta name="og:image" content={openGraphImage} />
				<meta property="og:image:width" content="1200" />
				<meta property="og:image:height" content="630" />
				<meta name="og:type" content="website" />
				<meta name="og:url" content={data?.data?.job?.applyUrl} />
				{/* Twitter */}
				<meta property="article:author" content="HrClerks Talent Solution" />
				<meta property="article:publisher" content="https://www.hrclerks.com" />
				<meta property="twitter:site_name" content="hrclerks.com" />
			</Helmet>

			<div
				className={`publicJobApplication z-[2000] bg-white p-[20px] md:p-[35px] ${
					applicationModal && 'h-screen'
				}`}
			>
				<div className="container mx-auto">
					{data?.data?.job?.company?.logo !== '' ? (
						<img
							alt="Company logo"
							src={data?.data?.job?.company?.logo}
							className="mb-5 h-fit max-h-[70px]"
						/>
					) : (
						<img alt="Company logo" src={logo} className="mb-5 h-fit max-h-[70px]" />
					)}

					<div className="rounded-sm border border-[#D9E1EC] p-[20px] md:p-[40px]">
						<div className="container mx-auto">
							<div className="mt-8">
								<MText className="font-semibold leading-[150%] text-black">
									<div className="flex justify-between">
										<p className="text-[24px]">
											{data?.data?.job?.title}{' '}
											<span className="text-[15px] font-semibold text-[#5AB180]">
												({data?.data?.job?.applications} Applicants)
											</span>{' '}
										</p>
										{hasAccessToken && (
											<CustomDropdown
												id={id}
												dropIcon={<HiDotsVertical />}
												handleConfirmationModal={handleConfirmationModal}
												handleShareJobModal={handleShareJobModal}
											/>
										)}
									</div>
								</MText>

								<JobCardData values={data?.data?.job} />

								{/* <div className="flex flex-wrap items-center justify-between gap-6">
										<div className="flex flex-wrap gap-4 text-sm">
											<JobCardPill className="text-[#5F6D83]">
												{data?.data?.job?.vacancy}
											</JobCardPill>
											<JobCardPill className="text-[#5F6D83]">
												{data?.data?.job?.workplaceType}
											</JobCardPill>
											<JobCardPill className="flex items-center gap-1 text-[#5F6D83]">
												<LocationSVG />
												{data?.data?.job?.location}
											</JobCardPill>
											<JobCardPill className="text-[#5F6D83]">
												{data?.data?.job?.jobType}
											</JobCardPill>
										</div>
										<div className="flex flex-wrap items-center gap-4">
											<p className="whitespace-nowrap text-sm font-medium text-[#5AB180]">
												{t('Publish on')}: {DateFormate(data?.data?.job?.createdAt)}
											</p>
											<p className="whitespace-nowrap text-sm font-medium text-[#E15241]">
												{t('Expires on')}: {DateFormate(data?.data?.job?.expiredAt)}
											</p>
										</div>
									</div> */}
								<hr className="my-[10px] w-full border border-[#A6AFBD]" />

								{/* <p className="text-lg text-[#3F4E64]">
										{data?.data?.job?.location}. {data?.data?.job?.jobType}
									</p> */}

								<div className="flex flex-col gap-4">
									<MText className="font-semibold leading-[150%] text-black">
										<h3 className="text-2xl">{t('Skills Required')}:</h3>
									</MText>

									<div className="flex flex-wrap gap-4">
										{data?.data?.job?.tags?.map((tag) => (
											<JobCardPill key={tag?.id}>{tag?.name}</JobCardPill>
										))}
									</div>

									<div className="mt-12 gap-4">
										{/* <Editor
											disabled
											apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
											initialValue={data?.data?.job?.description || ''}
											init={{
												menu: false,
												visual: false,
												toolbar: false,
												branding: false,
												readonly: true,
												menubar: false,
												plugins: 'autoresize',
												inline_boundaries: false,
												highlight_on_focus: false,
												editable_root: false,
												object_resizing: false,
												allow_html_in_named_anchor: false,
											}}
										/> */}

										<RichtextEditorField
											defaultValue={data?.data?.job?.description || ''}
											hideToolbar
											readOnly
										/>
									</div>

									{/* TODO: Create Apply now button and show modal multi-step form */}
									{data?.data?.job?.company?.name && (
										<MText className="text-[16px] font-semibold">
											About {data?.data?.job?.company?.name}
										</MText>
									)}

									{/* {data?.data?.job?.company?.description} */}
									{data?.data?.job?.company?.name && (
										<>
											{/* <Editor
												disabled
												apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
												initialValue={data?.data?.job?.company?.description || ''}
												init={{
													menu: false,
													visual: false,
													toolbar: false,
													branding: false,
													readonly: true,
													menubar: false,
													plugins: 'autoresize',
													inline_boundaries: false,
													highlight_on_focus: false,
													editable_root: false,
													object_resizing: false,
													allow_html_in_named_anchor: false,
												}}
											/> */}

											<RichtextEditorField
												name="description"
												defaultValue={data?.data?.job?.company?.description || ''}
												hideToolbar
												readOnly
											/>
										</>
									)}
								</div>

								<Button
									disabled={data?.data?.job?.expired || !data?.data?.job?.published}
									title="Apply position"
									onClick={() => setApplicationModal(true)}
									className={`mt-8 h-[64px] w-[193px] rounded-[8px] bg-[#1098FF] font-bold text-white ${
										(data?.data?.job?.expired || !data?.data?.job?.published) &&
										'cursor-not-allowed bg-gray-300'
									}`}
								/>
							</div>

							{isConfirmationModalOpen && (
								<ConformationModal
									handleConfirmationModal={handleConfirmationModal}
									handleJobDelete={handleJobDelete}
								/>
							)}

							<CongratulationModal
								toggleCongratsModal={toggleCongratsModal}
								setToggleCongratsModal={setToggleCongratsModal}
								jobId={deleteID}
								disableRedirection
								heading="Share with your connections!"
								title=""
							/>
						</div>
						<Text className="my-5 text-center">
							*{t('Powered by')} <span className="text-[#1098FF]">HrClerks ATS</span>
						</Text>

						<ApplyJobModal
							data={data}
							applicationModal={applicationModal}
							setApplicationModal={setApplicationModal}
							className={applicationModal ? '' : 'scale-0 opacity-0'}
						/>
					</div>
				</div>
			</div>
		</>
	);
};

export default PublicJobDetailsCompanyPage;
