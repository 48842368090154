/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-alert */
import { countries } from 'countries-list';
import { Formik } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import DropdownArrow from '../../assets/svgs/DropdownArrow';
import { SELECT_INDUSTRY, noOfEmployee } from '../../constants/CompanyEditInfo';
import TextInputField from '../atoms/TextInputField';
import DropdownWithLabel from '../molecules/DropdownWithLabel';

const SalesContactForm = ({ className = '' }) => {
	const { t } = useTranslation();
	// const [jobType, setJobType] = useState('');
	const [country, setCountry] = useState('');
	const [industryValue, setIndustryValue] = useState('');
	// const [workplaceType, setWorkplaceType] = useState('');
	const [totalEmployee, setTotalEmployee] = useState('');
	const countryNames = Object.values(countries).map((countryName) => countryName.name);
	const applicantInput =
		'h-[55px] w-full border-0 bg-[#F7F9FD] max-w-[420px] border border-[#02060C48]';
	const inputDiv = 'my-[12px] flex flex-wrap justify-between sm:flex-nowrap sm:gap-5';

	const initialValues = { firstName: '', lastName: '' };
	const validate = (values) => {
		const errors = {};
		if (!values.email) {
			errors.email = 'Required';
		} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
			errors.email = 'Invalid email address';
		}
		if (!values.firstName) {
			errors.firstName = 'Required';
		}
		if (!values.lastName) {
			errors.lastName = 'Required';
		}
		if (!values.phone) {
			errors.phone = 'Required';
		}
		if (!values.company) {
			errors.company = 'Required';
		}
		if (!values.state) {
			errors.state = 'Required';
		}
		if (!values.establishmentDate) {
			errors.establishmentDate = 'Required';
		}
		return errors;
	};

	const handleFormSubmit = (values, { setSubmitting }) => {
		// eslint-disable-next-line no-unused-vars
		const allValues = {
			...values,
			country,
			totalEmployee,
			// workplaceType,
			industry: industryValue,
		};
		setTimeout(() => {
			alert(JSON.stringify(values, null, 2));
			setSubmitting(false);
		}, 400);
	};

	return (
		<div className={`z-10 bg-white shadow-[-20px_35px_60px_10px_rgba(0,0,0,0.3)] ${className}`}>
			<Formik
				initialValues={initialValues}
				// validate={(values) => {
				// 	const errors = {};
				// 	if (!values.email) {
				// 		errors.email = 'Required';
				// 	} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
				// 		errors.email = 'Invalid email address';
				// 	}
				// 	return errors;
				// }}
				validate={validate}
				onSubmit={handleFormSubmit}
			>
				{({
					values,
					errors,
					touched,
					handleChange,
					handleBlur,
					handleSubmit,
					isSubmitting,
					/* and other goodies */
				}) => (
					<form onSubmit={handleSubmit}>
						<div className={inputDiv}>
							<TextInputField
								type="text"
								name="firstName"
								onChange={handleChange}
								onBlur={handleBlur}
								value={values.firstName}
								error={errors.firstName && touched.firstName && errors.firstName}
								placeholder="First Name *"
								className={applicantInput}
								required
							/>
							<TextInputField
								type="text"
								name="lastName"
								onChange={handleChange}
								onBlur={handleBlur}
								value={values.lastName}
								placeholder="Last Name *"
								className={applicantInput}
								error={errors.lastName && touched.lastName && errors.lastName}
								required
							/>
						</div>
						<div className={inputDiv}>
							<TextInputField
								type="email"
								name="email"
								onChange={handleChange}
								onBlur={handleBlur}
								value={values.email}
								error={errors.email && touched.email && errors.email}
								placeholder="Email *"
								className={applicantInput}
								required
							/>
							<TextInputField
								type="Number"
								name="phone"
								onChange={handleChange}
								onBlur={handleBlur}
								value={values.number}
								placeholder="Phone Number *"
								className={applicantInput}
								error={errors.phone && touched.phone && errors.phone}
								required
							/>
						</div>

						<div className={inputDiv}>
							<TextInputField
								type="text"
								name="company"
								onChange={handleChange}
								onBlur={handleBlur}
								value={values.company}
								error={errors.company && touched.company && errors.company}
								placeholder="Company Name *"
								className={applicantInput}
								required
							/>
							<DropdownWithLabel
								items={SELECT_INDUSTRY}
								value={industryValue}
								icon={<DropdownArrow className="h-full" />}
								defaultPlaceholder={t('Select Industry')}
								onChange={(option) => setIndustryValue(option)}
								className={applicantInput}
								buttonClassName={`${applicantInput} ${
									values?.industry !== 'Select Industry' ? 'text-[#02060C]' : 'text-[#A6AFBD]'
								} `}
								itemBoxClassName={applicantInput}
								errorClassName="static ms-2 mt-1 text-start text-sm"
								error={touched?.industry && errors?.industry}
							/>
						</div>

						<div className={inputDiv}>
							<DropdownWithLabel
								items={countryNames}
								value={country}
								icon={<DropdownArrow className="h-full" />}
								defaultPlaceholder={t('Country')}
								onChange={(option) => setCountry(option)}
								className={applicantInput}
								buttonClassName={`${applicantInput} ${
									values?.industry !== 'Select Industry' ? 'text-[#02060C]' : 'text-[#A6AFBD]'
								} `}
								itemBoxClassName={applicantInput}
								errorClassName="static ms-2 mt-1 text-start text-sm"
								error={touched?.industry && errors?.industry}
							/>
							<TextInputField
								type="text"
								name="state"
								onChange={handleChange}
								onBlur={handleBlur}
								value={values.state}
								error={errors.state && touched.state && errors.state}
								placeholder="State/City"
								className={applicantInput}
								required
							/>
						</div>

						<div className={inputDiv}>
							<DropdownWithLabel
								items={noOfEmployee}
								value={totalEmployee}
								icon={<DropdownArrow className="h-full" />}
								defaultPlaceholder={t('No. of Employees')}
								onChange={(option) => setTotalEmployee(option)}
								className={applicantInput}
								buttonClassName={`${applicantInput} ${
									values?.industry !== 'Select Industry' ? 'text-[#02060C]' : 'text-[#A6AFBD]'
								} `}
								itemBoxClassName={applicantInput}
								errorClassName="static ms-2 mt-1 text-start text-sm"
								error={touched?.industry && errors?.industry}
							/>

							<TextInputField
								type="date"
								id="establishmentDate"
								name="establishmentDate"
								value={values?.establishmentDate}
								onChange={handleChange}
								onBlur={handleBlur}
								min={values?.publishDate}
								className={applicantInput}
								customPlaceholder={!values?.establishmentDate && t('Expired Date')}
								error={touched?.establishmentDate && errors?.establishmentDate}
							/>
						</div>

						{/* <div className={inputDiv}>
							<DropdownWithLabel
								items={WORK_PLACE_TYPE_LIST}
								value={workplaceType}
								icon={<DropdownArrow className="h-full" />}
								defaultPlaceholder={t('Workplace Type')}
								onChange={(option) => setWorkplaceType(option)}
								buttonClassName={`bg-white h-12 rounded-md px-6 py-3 font-medium border-[#EFF1F3] ${
									values?.workplaceType !== 'Select Work Place Type'
										? 'text-[#02060C]'
										: 'text-[#A6AFBD]'
								} `}
								itemBoxClassName="font-medium text-[#02060C] border-[#EFF1F3] rounded-b-md"
								errorClassName="static ms-2 mt-1 text-start text-sm"
								error={touched?.workplaceType && errors?.workplaceType}
							/>

							<TextInputField
								onChange={handleChange}
								onBlur={handleBlur}
								placeholder={t('Location')}
								id="location"
								name="location"
								value={values?.location}
								icon={<TargetLocation className="me-6" />}
								error={touched?.location && errors?.location}
							/>
						</div> */}
						<hr className="mb-7 w-full border border-[#A6AFBD]" />

						<label htmlFor="w3review">
							<p className="font-semibold text-[#5F6D83]">Anything else? (Optional)</p>
							<textarea
								id="w3review"
								name="w3review"
								onChange={handleChange}
								onBlur={handleBlur}
								value={values.w3review}
								className="applicantInput my-5 min-h-[124px] w-full rounded-[4px] border border-[#A6AFBD] placeholder-[#A6AFBD] outline-none"
								placeholder="What challenges are you looking to overcome with HrClerks ATS?"
							/>
						</label>

						<p className="text-[#A6AFBD]">
							By registering, you confirm that you agree to the processing of your personal data by
							HrClerks Talent Solutions as described in the{' '}
							<span className="cursor-pointer whitespace-nowrap text-[#1098FF] underline">
								Privacy Statement
							</span>
							.
						</p>

						<div className="my-5 block lg:hidden">
							<input
								type="checkbox"
								className="float-left mr-3 mt-1 rounded-[4px] border border-[#A6AFBD]"
							/>
							<p className="text-[#A6AFBD]">
								Yes, I would like to receive marketing communications about the EWN family of
								companies' products, services, and events. I can unsubscribe at any time.
							</p>
						</div>

						<div className="my-5 hidden items-center gap-1 lg:flex">
							<input type="checkbox" className="mr-3 rounded-[4px] border border-[#A6AFBD] p-2" />
							<p className="text-[#A6AFBD]">
								Yes, I would like to receive marketing communications about the EWN family of
								companies' products, services, and events. I can unsubscribe at any time.
							</p>
						</div>

						<button
							type="submit"
							disabled={isSubmitting}
							className="rounded-lg bg-[#1098FF] px-[72px] py-3 font-semibold text-white"
						>
							Submit
						</button>
					</form>
				)}
			</Formik>
		</div>
	);
};

export default SalesContactForm;
