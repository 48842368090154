// export default Footer;
import { BsFillTelephoneFill, BsTwitter } from 'react-icons/bs';
import { FaFacebookF, FaLinkedinIn, FaYoutube } from 'react-icons/fa';
import { FiMail } from 'react-icons/fi';
import { Link as RouteLink } from 'react-router-dom';
import { Link } from 'react-scroll';

import { useTranslation } from 'react-i18next';
import logo from '../../assets/svgs/hr-clerk-footer-logo.svg';
import LanguageButton from './LanguageButton';

const Footer = ({ language = '', setLanguage = null }) => {
	// const [email, setEmail] = useState('');
	const { t } = useTranslation();

	return (
		<footer className="bg-[#122737] px-5 py-10">
			<section className="container mx-auto">
				<div className="border-b-2 border-white border-opacity-10">
					<div className="mx-auto w-full py-10 text-center md:w-[60%]">
						<h3 className="font-Montserrat text-lg font-bold leading-[53px] text-white md:text-[43px]">
							{t('Ready to become a member ?')}
						</h3>
						<div className="mx-auto my-5 w-full max-w-3xl">
							<p className="font-Montserrat text-sm font-normal leading-[30px] text-white opacity-60 md:text-[18px]">
								{t(
									'Unlock your recruitment potential today! Enter your email below and kickstart your journey with HrClerks ATS – where seamless hiring meets unparalleled efficiency. Your next great hire is just a click away!'
								)}
							</p>
						</div>

						{/* <form className="my-5 flex flex-col items-center gap-5 md:flex-row md:justify-center md:gap-2">
							<input
								type="email"
								className="w-full rounded-[4px] px-8 py-4 font-Montserrat font-normal text-[#81838C] outline-none md:w-80"
								placeholder={t('Your email')}
								value={email}
								onChange={(e) => {
									setEmail(e.target.value);
								}}
							/>
							<RouteLink to={{ pathname: '/signup', query: { email } }}>
								<Button
									type="submit"
									title={t('Join us Now')}
									className="w-full rounded-[4px] bg-[#2BD67B] px-10 py-4 text-base font-bold text-white md:w-60"
								/>
							</RouteLink>
						</form> */}
						{/* <div className="md:flex">
							<RouteLink to={{ pathname: '/signup' }}>
								<Button
									type="submit"
									title={t('Talk to Sales')}
									className="w-fit max-w-[300px] border border-[#1098FF] bg-[#F7F9FD] px-10 py-4 text-base font-bold text-[#1098ff] sm:w-full"
								/>
							</RouteLink>
							<RouteLink to={{ pathname: '/signup' }} className="border md:border-red">
								<Button
									type="submit"
									title={t('Try for Free')}
									className="mt-5 w-fit max-w-[300px] border border-[#1098FF] bg-[#1098FF] px-10 py-4 text-base font-bold text-white sm:w-full md:ms-5 md:mt-0"
								/>
							</RouteLink>
						</div> */}

						<div className="mx-auto flex max-w-[600px] flex-col items-center justify-between gap-5 sm:flex-row">
							<RouteLink
								to="/contact-sales"
								className="z-10 border border-[#1098FF] bg-[#F7F9FD] px-10 py-4 text-base font-bold text-[#1098ff] sm:w-[48%]"
							>
								{t('Talk to Sales')}
							</RouteLink>

							<RouteLink
								to="/signup"
								className="z-10 cursor-pointer border border-[#1098FF] bg-[#1098FF] px-10 py-4 text-base font-bold text-[#F7F9FD] sm:w-[48%]"
							>
								{t('Try for Free')}
							</RouteLink>
						</div>
					</div>
				</div>

				<div className="my-[60px] flex justify-between gap-10">
					<div className="relative mb-10">
						<Link to="home">
							<img src={logo} alt="" className="cursor-pointer" />
						</Link>

						<LanguageButton language={language} setLanguage={setLanguage} />

						<div className="absolute bottom-0 mt-auto block">
							<h3 className="font-Montserrat text-base font-normal text-white">
								{t('Follow us on')} :
							</h3>
							<div className="mt-4 flex gap-3">
								<FaFacebookF className="cursor-pointer text-xl text-white hover:text-[#2BD67B]" />
								<BsTwitter className="cursor-pointer text-xl text-white hover:text-[#2BD67B]" />
								<FaLinkedinIn className="cursor-pointer text-xl text-white hover:text-[#2BD67B]" />
								<FaYoutube className="cursor-pointer text-xl text-white hover:text-[#2BD67B]" />
							</div>
						</div>
					</div>
					<div className="ml-auto flex-wrap gap-[72px] sm:flex">
						<div className="font-Montserrat text-[14.4px] text-white">
							<h4 className="font-bold">{t('Products')}</h4>
							<ul className="mt-10">
								<li className="mt-[19px] cursor-pointer font-normal hover:text-gray-300">
									<RouteLink to="/">{t('ATS')}</RouteLink>
								</li>
								<li className="mt-[19px] cursor-pointer font-normal hover:text-gray-300">
									<a href="http://app.hrclerks.com" target="_blank" rel="noreferrer">
										{t('Slack Application')}
									</a>
								</li>
								<li className="mt-[19px] cursor-pointer font-normal hover:text-gray-300">
									<a href="http://jobs.hrclerks.com" target="_blank" rel="noreferrer">
										{t('Job Board')}
									</a>
								</li>
							</ul>
						</div>
						<div className="font-Montserrat text-[14.4px] text-white">
							<h4 className="font-bold">{t('Company')}</h4>
							<ul className="mt-10">
								<li className="mt-[19px] cursor-pointer font-normal hover:text-gray-300">
									<Link to="about" smooth duration={800}>
										{t('About')}
									</Link>
								</li>
								<li className="mt-[19px] cursor-pointer font-normal hover:text-gray-300">
									<Link to="features" smooth duration={800}>
										{t('Features')}
									</Link>
								</li>
								<li className="mt-[19px] cursor-pointer font-normal hover:text-gray-300">
									<a href="http://blog.hrclerks.com" target="_blank" rel="noreferrer">
										{t('Blog')}
									</a>
								</li>
								<li className="mt-[19px] cursor-pointer font-normal hover:text-gray-300">
									<Link to="pricing" smooth duration={800}>
										{t('Pricing')}
									</Link>
								</li>
								<li className="mt-[19px] cursor-pointer font-normal hover:text-gray-300">
									<Link to="testimonials" smooth duration={800}>
										{t('Testimonials')}
									</Link>
								</li>
							</ul>
						</div>
						<div className="font-Montserrat text-[14.4px] text-white">
							<h4 className="font-bold">{t('Legal')} </h4>
							<ul className="mt-10">
								<li className="mt-[19px] cursor-pointer font-normal hover:text-gray-300">
									<RouteLink to="/termsConditions">{t('Terms & Conditions')}</RouteLink>
								</li>
								<li className="mt-[19px] cursor-pointer font-normal hover:text-gray-300">
									<RouteLink to="/policyPage">{t('Privacy Policies')}</RouteLink>
								</li>
							</ul>
						</div>
						<div className="font-Montserrat text-[14.4px] text-white">
							<h4 className="font-bold">{t('Get in Touch')} </h4>
							<div className="mt-10">
								<div className="flex gap-4">
									<FiMail className="w-[22px] text-[30px]" />
									<div>
										<a href="mailto:contact@hrclerks.com">
											<p className="mb-3 cursor-pointer">contact@hrclerks.com</p>
										</a>
										<a href="mailto:support@hrclerks.com">
											<p className="mb-3 cursor-pointer">support@hrclerks.com</p>
										</a>
									</div>
								</div>
								<div className="mt-5 flex gap-4">
									<BsFillTelephoneFill className="w-[22px] text-[30px]" />
									<div>
										<a href="tel:+8801984743393">
											<p className="cursor-pointer">+8801984743393</p>
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</footer>
	);
};

export default Footer;
