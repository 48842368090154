import LText from '../atoms/LText';
import Text from '../atoms/Text';

const FeatureTalentCard = ({ title = '', number = '', description = '' }) => {
	return (
		<div className="mt-5 max-w-[517px] rounded-md border border-[#5F6D83] px-4">
			<div className="relative w-full flex-col items-center gap-5 lg:flex-row lg:justify-between">
				<div className="w-full">
					<div className="outlined-number" style={{ fontSize: '65px' }}>
						{number}
					</div>
					<LText className="absolute top-12 overflow-hidden whitespace-nowrap">
						<p className="font-bold text-[#0D152E]">{title}</p>
					</LText>
					{/* <Heading text={title} className="absolute top-12 overflow-hidden md:text-lg" /> */}
					<Text className="font-base my-5 mt-10 w-full font-Montserrat text-base text-[#81838C] md:mt-0">
						{description}
					</Text>
				</div>
				<div className="w-36">
					{/* <Button
						title="View Details"
						className="my-5 w-36 rounded-md border border-[#1098FF] bg-[#fff] py-3 text-base font-semibold text-[#1098FF]"
					/> */}
				</div>
			</div>
		</div>
	);
};

export default FeatureTalentCard;
