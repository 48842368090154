/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { useMutation } from '@tanstack/react-query';
import { LoaderIcon } from 'react-hot-toast';
import { uploadFile } from '../../apis/applicationAPIs';
import RedCircleCrossSvg from '../../assets/svgs/RedCircleCrossSvg';
import TextInputField from './TextInputField';

const ApplyJobsFirstForm = ({
	handleChange,
	handleBlur,
	values,
	errors,
	touched,
	className = '',
	setIsResume,
	fileName,
	setFileName,
}) => {
	// const [fileName, setFileName] = useState('');
	const applicantInput = 'h-[80px] w-full border-0 bg-[#F7F9FD]';

	const { mutateAsync, isPending } = useMutation({
		mutationFn: uploadFile,
		onSuccess: (FileValue) => {
			values.resume = FileValue.data.url;
		},
	});

	const handleFile = async (e) => {
		setFileName(e.target.value);
		const file = e.target.files[0];
		const formData = new FormData();
		formData.append('file', file);
		const fileData = await mutateAsync(formData);
		setIsResume(false);
	};

	const handleRemoveFile = () => {
		setFileName('');
		setIsResume(true);
	};

	return (
		<div className={className}>
			<div className="my-[10px] flex flex-wrap sm:flex-nowrap sm:gap-12">
				<TextInputField
					type="text"
					name="firstName"
					onChange={handleChange}
					onBlur={handleBlur}
					value={values.firstName}
					error={errors.firstName && touched.firstName && errors.firstName}
					placeholder="First Name"
					className={applicantInput}
					required
				/>
				<TextInputField
					type="text"
					name="lastName"
					onChange={handleChange}
					onBlur={handleBlur}
					value={values.lastName}
					placeholder="Last Name"
					className={applicantInput}
					error={errors.lastName && touched.lastName && errors.lastName}
					required
				/>
			</div>
			<div className="my-[10px] flex flex-wrap sm:flex-nowrap sm:gap-12">
				<TextInputField
					type="email"
					name="email"
					onChange={handleChange}
					onBlur={handleBlur}
					value={values.email}
					error={errors.email && touched.email && errors.email}
					placeholder="Email"
					className={applicantInput}
					required
				/>
				<TextInputField
					type="Number"
					name="phone"
					onChange={handleChange}
					onBlur={handleBlur}
					value={values.phone}
					placeholder="Phone Number"
					className={applicantInput}
					error={errors.phone && touched.phone && errors.phone}
					required
				/>
			</div>

			<div className="my-[10px] flex flex-wrap sm:flex-nowrap sm:gap-12">
				<TextInputField
					type="text"
					name="presentAddress"
					onChange={handleChange}
					onBlur={handleBlur}
					value={values.presentAddress}
					error={errors.presentAddress && touched.presentAddress && errors.presentAddress}
					placeholder="Present Address"
					className={applicantInput}
				/>
				<TextInputField
					type="text"
					name="parmanentAddress"
					onChange={handleChange}
					onBlur={handleBlur}
					value={values.parmanentAddress}
					error={errors.parmanentAddress && touched.parmanentAddress && errors.parmanentAddress}
					placeholder="Permanent Address"
					className={applicantInput}
				/>
			</div>

			<div className="mb-10 flex gap-5">
				<p className="font-bold">Resume/CV*</p>
				<div className="file-input-container flex gap-5">
					<input
						key={fileName}
						type="file"
						name="resume"
						id="resume"
						onChange={(e) => handleFile(e)}
						accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf"
						style={{ display: 'none' }}
						disabled={isPending}
					/>
					{fileName === '' && (
						<label
							htmlFor="resume"
							className={`flex cursor-pointer items-center gap-2 font-medium text-blue-500 ${
								isPending && 'text-gray-300'
							}`}
						>
							Attach
							{isPending && <LoaderIcon />}
						</label>
					)}
					{fileName !== '' && (
						<span className="flex items-center gap-3 font-medium text-[#1098FF]">
							{fileName.split(`\\`)[fileName.split(`\\`).length - 1]}{' '}
							<button type="button" label onClick={() => handleRemoveFile()}>
								<RedCircleCrossSvg />
							</button>
						</span>
					)}
				</div>
			</div>
			{/* <div className="h-[90px]" /> */}
		</div>
	);
};

export default ApplyJobsFirstForm;
