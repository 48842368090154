/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Suspense, useEffect, useState } from 'react';
import { MdOutlineArrowRight } from 'react-icons/md';
import { useLocation } from 'react-router-dom';

import DashboardNavbar from '../components/molecules/DashboardNavbar';
import FullPageSpinner from '../components/molecules/FullPageSpinner';
import Sidebar from '../components/molecules/Sidebar';
import useAuth from '../hooks/useAuth';
import routeConfig from '../routes/routeConfig';
import routes from '../routes/routes';

const BaseLayout = () => {
	const [isSidebarOpen, setIsSidebarOpen] = useState(false);
	const [narrowSidebar, setNarrowSidebar] = useState(false);
	const [isNavbarMenuOpen, setIsNavbarMenuOpen] = useState(true);

	const location = useLocation();
	const pathPattern = /^\/open\/publicForm\/[0-9a-zA-Z]+$/;
	const currentPath = location.pathname;

	const { state: hasAccessToken } = useAuth();

	const toggleSidebar = () => {
		setIsSidebarOpen(!isSidebarOpen);
	};

	const toggleNavbarMenu = () => {
		setIsNavbarMenuOpen(!isNavbarMenuOpen);
	};

	useEffect(() => {
		if (
			['/', '/signin', '/forgot-password', '/signup'].includes(currentPath) ||
			currentPath.includes('/open/job')
		) {
			setIsSidebarOpen(false);
		} else {
			// setIsSidebarOpen(true);
		}
	}, [currentPath]);

	return (
		<div className="flex flex-col">
			{hasAccessToken && currentPath !== '/' && !pathPattern.test(currentPath) && (
				<DashboardNavbar toggleSidebar={toggleSidebar} toggleNavbarMenu={toggleNavbarMenu} />
			)}

			<div className="fixed z-50 flex w-fit flex-1 bg-[#fff]">
				{hasAccessToken && currentPath !== '/' && !pathPattern.test(currentPath) && (
					<Sidebar
						isOpen={isSidebarOpen}
						toggleSidebar={toggleSidebar}
						narrowSidebar={narrowSidebar}
						setNarrowSidebar={setNarrowSidebar}
					/>
				)}

				<div
					className={`transition-margin relative w-full bg-white duration-300 lg:hidden ${
						isSidebarOpen ? 'ml-0' : '-ml-72'
					}`}
				>
					{hasAccessToken && location.pathname !== '/' && (
						<div className="hover:opacity-1.5 absolute -left-3 top-[50%] flex cursor-pointer items-center justify-start rounded-full drop-shadow-lg transition-all duration-300 ease-in-out hover:scale-150 hover:bg-[#FBFCFD]">
							<span
								onClick={toggleSidebar}
								className={`transform ${isSidebarOpen ? 'rotate-180' : ''}`}
							>
								<MdOutlineArrowRight size={30} />
							</span>
						</div>
					)}
				</div>
			</div>

			<main
				className={`overflow-hidden ${
					hasAccessToken && currentPath !== '/' && currentPath !== '/open/publicForm' ? 'mt-20' : ''
				} ${
					currentPath === '/' ||
					currentPath === '/signin' ||
					currentPath === '/signup' ||
					currentPath === '/contact-sales' ||
					currentPath === '/open/jobs/1'
						? 'lg:ml-0'
						: ''
				} ${
					// currentPath === '/' ||
					currentPath.search(/open/i) === 1 || isSidebarOpen
						? currentPath !== '/' && !pathPattern.test(currentPath) && 'lg:ml-[60px]'
						: currentPath !== '/' && !pathPattern.test(currentPath) && narrowSidebar
						? 'ml-[60px]'
						: currentPath !== '/' &&
						  currentPath !== '/signin' &&
						  currentPath !== '/signup' &&
						  currentPath !== '/contact-sales' &&
						  currentPath !== '/open/jobs/1' &&
						  'ml-[234px]'
				}`}
				// className={`${narrowSidebar ? 'ml-[60px]' : 'ml-[234px]'}`}
			>
				<Suspense fallback={<FullPageSpinner />}>{routeConfig(routes)}</Suspense>
			</main>
		</div>
	);
};

export default BaseLayout;
