import PropTypes from 'prop-types';

const DropdownArrow = ({
	width = '16',
	height = '20',
	className = 'text-[#02060C]',
	style = {},
}) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={width}
			height={height}
			className={className}
			style={style}
			fill="none"
			viewBox={`0 0 ${width} ${height}`}
		>
			<path
				d="M2.8999 5.6001L8.4999 11.2001L14.0999 5.6001"
				stroke="currentColor"
				strokeWidth="2"
				strokeLinecap="square"
			/>
		</svg>
	);
};

DropdownArrow.propTypes = {
	width: PropTypes.string,
	height: PropTypes.string,
	className: PropTypes.string,
	style: PropTypes.shape({}),
};

DropdownArrow.defaultProps = {
	width: '16',
	height: '16',
	className: '',
	style: {},
};

export default DropdownArrow;
