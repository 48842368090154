import applications from '../assets/svgs/applicants.svg';
import jobCircular from '../assets/svgs/job-circular.svg';

export const NavMenuLinks = [
	// {
	// 	name: 'Home',
	// 	submenu: false,
	// 	link: 'home',
	// },
	{
		name: 'About',
		submenu: false,
		link: 'about',
	},
	{
		name: 'Features',
		submenu: false,
		link: 'features',
	},
	{
		name: 'Pricing',
		submenu: false,
		link: 'pricing',
	},
	{
		name: 'Job-Board',
		submenu: false,
		link: 'http://jobs.hrclerks.com',
		originalLink: true,
	},
];

export const DashboardSidebarItem = [
	{
		id: 1,
		icon: jobCircular,
		text: 'Job-Circular',
		path: '/dashboard/job-circular',
	},
	{
		id: 2,
		icon: applications,
		text: 'Applicants',
		path: '/dashboard/applicants',
	},
	{
		id: 3,
		icon: applications,
		text: 'Subscriptions',
		path: '/dashboard/subscriptions',
	},
];
