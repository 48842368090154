const Text = ({ children = null, className = '', style = {}, placeholder }) => {
	return (
		<div
			style={style}
			className={`font-Montserrat text-[16px] font-medium leading-[24px] text-[#687497] ${className}`}
			placeholder={placeholder}
		>
			{children}
		</div>
	);
};

export default Text;
