const SectionTitle = ({ children = null, className = '' }) => {
	return (
		<div
			className={`text-center font-Montserrat text-[14px] font-bold uppercase leading-[17px] text-[#1098FF] ${className}`}
		>
			{children}
		</div>
	);
};

export default SectionTitle;
