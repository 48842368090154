/* eslint-disable jsx-a11y/control-has-associated-label */
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import LanguageSVG from '../../assets/svgs/LanguageSVG';
import UpArrowSVG from '../../assets/svgs/UpArrowSVG';
import WriteSVG from '../../assets/svgs/WriteSVG';
import { languages } from '../../constants/languages';
import useLanguage from '../../hooks/useLanguage';
import useOutClick from '../../hooks/useOutClick';
import { setLanguage as setLanguageAction } from '../../reducers/languageReducer';

const LanguageButton = ({ className = '' }) => {
	const [toggleLanguages, setToggleLanguages] = useState(false);
	const languageButtonRef = useRef();

	const { i18n } = useTranslation();
	const { dispatch } = useLanguage();

	const currentLan = localStorage.getItem('language');

	useOutClick(languageButtonRef, () => {
		setToggleLanguages(false);
	});

	const handleChangeLanguage = (item) => {
		dispatch(setLanguageAction(item?.value));
		localStorage.setItem('language', JSON.stringify(item));
		i18n?.changeLanguage(item.i18name);
	};

	return (
		<div
			ref={languageButtonRef}
			className={`flex cursor-pointer flex-row items-center justify-end ${className}`}
		>
			<button
				type="button"
				onClick={() => setToggleLanguages((oldState) => !oldState)}
				className="mt-[42px] flex h-[36px] w-[130px] items-center justify-center gap-[4px] rounded-full border border-[#1098FF] text-[14px] font-semibold text-[#1098FF]"
			>
				<span className="uppercase">{currentLan && JSON.parse(currentLan)?.label}</span>
				<LanguageSVG />
			</button>
			<div
				className={`absolute top-14 w-[174px] rounded-[12px] bg-white p-[20px] shadow-xl transition-all duration-200 ${
					!toggleLanguages && 'scale-0'
				}`}
			>
				<table className="w-full">
					{languages?.map((item) => (
						<tr
							key={item?.id}
							onClick={() => handleChangeLanguage(item)}
							className="w-full cursor-pointer text-white first:text-[#1098FF]"
						>
							<td>
								<WriteSVG
									className={
										currentLan && JSON.parse(currentLan)?.label === item?.label
											? 'text-[#00FFAB]'
											: 'text-white'
									}
								/>
							</td>
							<td
								className={`py-[10px] ${
									currentLan && JSON.parse(currentLan)?.label === item?.label
										? 'text-[#1098FF]'
										: 'text-black'
								}`}
							>
								{item?.label}
							</td>
							<td>
								<UpArrowSVG className="" />
							</td>
						</tr>
					))}
				</table>
			</div>
		</div>
	);
};

export default LanguageButton;
