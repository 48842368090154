import { Listbox } from '@headlessui/react';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';

import InputDownSVG from '../../assets/svgs/InputDownSVG';
import InputLabel from '../atoms/InputLabel';

const DropdownWithLabel = ({
	labelText = '',
	items,
	value,
	error,
	errorClassName = '',
	onChange,
	onBlur,
	icon = '',
	defaultPlaceholder = '',
	buttonClassName = '',
	itemBoxClassName = '',
	placeholder = '',
}) => {
	const { t } = useTranslation();

	return (
		<div className={`${labelText ? '' : 'pb-3'} relative w-full`}>
			<InputLabel text={labelText} />
			<Listbox value={value} onChange={onChange}>
				<Listbox.Button
					onBlur={onBlur}
					className={twMerge(
						'flex h-14 w-full justify-between overflow-hidden whitespace-nowrap rounded border border-[#EFF1F3] p-4 text-[#A6AFBD]',

						buttonClassName
					)}
				>
					<p
						className={`flex w-full items-center justify-between ${
							!value ? 'text-[#A6AFBD]' : 'text-[#000]'
						}`}
					>
						{value || defaultPlaceholder || placeholder || t('Select an item')}
						{icon || <InputDownSVG />}
					</p>
				</Listbox.Button>
				<Listbox.Options
					className={twMerge(
						'absolute top-14 z-30 max-h-[500px] min-h-[200px] w-full cursor-pointer overflow-y-scroll rounded border border-gray-300 bg-white',
						itemBoxClassName
					)}
				>
					<Listbox.Option className="hover:bg-gray-30 p-4" key="" value="">
						{defaultPlaceholder || t('Select an item')}
					</Listbox.Option>
					{items?.map((item) => (
						<Listbox.Option
							className={`p-4 normal-case hover:bg-gray-300 ${
								item === value ? 'bg-gray-300' : ''
							} `}
							key={item}
							value={item}
						>
							{item}
						</Listbox.Option>
					))}
				</Listbox.Options>
			</Listbox>
			{error && <p className={twMerge(' text-red', errorClassName)}>{t(error)}</p>}
		</div>
	);
};

export default DropdownWithLabel;
