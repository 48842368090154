const baseURL =
	process.env.NODE_ENV === 'production'
		? 'https://ats-admin.hrclerks.com'
		: 'https://ats-admin.hrclerks.com';

export default baseURL;

// https://ats-admin.hrclerks.com
// http://localhost:4000
// http://192.168.68.141:4000
