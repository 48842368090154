import { useTranslation } from 'react-i18next';
import StayAtHomeSVG from '../../assets/svgs/StayAtHomeSVG';
import FeatureTalentCard from '../molecules/FeatureTalentCard';

const TalentFeatureSection = () => {
	const { t } = useTranslation();

	return (
		<section className="container mx-auto my-10">
			<div
				className="flex w-full flex-col items-center gap-5 md:flex-row
      lg:px-5"
			>
				<div className="flex w-full justify-center md:w-1/2">
					<StayAtHomeSVG className="mx-auto w-full px-5 sm:w-fit sm:px-0 lg:mx-0 " />
				</div>
				<div className="flex w-full flex-col items-center px-5 md:w-1/2 lg:px-0">
					<FeatureTalentCard
						number="07"
						title={t('Personalized Talent Pooling')}
						description={t(
							'Build your dream team with Personalized Talent Pooling – tailor your pool, find the perfect fit!'
						)}
					/>
					<FeatureTalentCard
						number="08"
						title={t('Localization support')}
						description={t(
							'Seamless global experience with Localization Support – use the app in your native language!'
						)}
					/>
					<FeatureTalentCard
						number="09"
						title={t('Keyword Search')}
						description={t(
							'Find what you need with ease through Keyword Search. Simplifying your search process, one keyword at a time.'
						)}
					/>
					<FeatureTalentCard
						number="10"
						title={t('Export Data on CSV Format')}
						description={t('Effortless data management: Export to CSV for streamlined efficiency.')}
					/>
				</div>
			</div>
		</section>
	);
};

export default TalentFeatureSection;
