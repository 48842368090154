const LimoSVG = () => {
	return (
		<svg
			width="163"
			height="98"
			viewBox="0 0 163 98"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect x="0.658203" width="162" height="98" rx="4" fill="#0C73DC" />
			<g clipPath="url(#clip0_6166_21859)">
				<path
					d="M53.7233 36.9282H50.0879V43.225H53.7255C54.916 43.225 55.8847 44.1506 55.8847 45.2882V60.3479H62.4746V45.2882C62.4746 40.679 58.5492 36.9282 53.7233 36.9282Z"
					fill="white"
				/>
				<path
					d="M41.248 50.2752V36.8022H34.6582V50.2752C34.6582 55.8373 39.3919 60.3605 45.2129 60.3605H53.2832V54.0637H45.2129C43.0273 54.0637 41.248 52.3657 41.248 50.2752Z"
					fill="white"
				/>
				<path
					d="M86.3706 36.7622H85.4019C83.381 36.7622 81.5205 37.4297 80.0465 38.5442C78.5726 37.4297 76.7121 36.7622 74.6912 36.7622H73.7225C68.9537 36.7622 65.0723 40.471 65.0723 45.0277V60.3477H71.6621V45.0277C71.6621 43.9426 72.5869 43.059 73.7225 43.059H74.6912C75.8269 43.059 76.7516 43.9426 76.7516 45.0277V60.3477V60.608H83.3415V60.3477V45.0277C83.3415 43.9426 84.2662 43.059 85.4019 43.059H86.3706C87.5062 43.059 88.431 43.9426 88.431 45.0277V60.608H95.0208V45.0277C95.0208 40.471 91.1394 36.7622 86.3706 36.7622Z"
					fill="white"
				/>
				<path
					d="M108.89 36.7622C102.801 36.7622 97.8477 41.4953 97.8477 47.3135V49.8091C97.8477 55.6273 102.801 60.3603 108.89 60.3603C114.979 60.3603 119.932 55.6273 119.932 49.8091V47.3135C119.932 41.4953 114.979 36.7622 108.89 36.7622ZM113.343 49.8091C113.343 52.1557 111.346 54.0636 108.89 54.0636C106.434 54.0636 104.437 52.1557 104.437 49.8091V47.3135C104.437 44.9669 106.434 43.059 108.89 43.059C111.346 43.059 113.343 44.9669 113.343 47.3135V49.8091Z"
					fill="white"
				/>
				<path
					d="M124.259 36.8022C122.384 36.8022 120.861 38.2568 120.861 40.0493C120.861 41.8417 122.384 43.2963 124.259 43.2963C126.135 43.2963 127.658 41.8417 127.658 40.0493C127.658 38.2547 126.135 36.8022 124.259 36.8022Z"
					fill="white"
				/>
			</g>
			<defs>
				<clipPath id="clip0_6166_21859">
					<rect width="93" height="23.8458" fill="white" transform="translate(34.6582 36.7622)" />
				</clipPath>
			</defs>
		</svg>
	);
};

export default LimoSVG;
