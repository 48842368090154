const CricleWrite = ({ width = '20', height = '21', className = '', style = {} }) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox={`0 0 ${width} ${height}`}
			className={className}
			style={style}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M9.9999 17.1C13.645 17.1 16.5999 14.1451 16.5999 10.5C16.5999 6.85494 13.645 3.90002 9.9999 3.90002C6.35482 3.90002 3.3999 6.85494 3.3999 10.5C3.3999 14.1451 6.35482 17.1 9.9999 17.1Z"
				fill="#8AC5FB"
			/>
			<path
				d="M13.4957 8.1987C13.2802 7.97147 12.921 7.96146 12.6934 8.1775L9.07003 11.6138L7.3418 9.83935C7.12291 9.61477 6.76402 9.60985 6.53926 9.82877C6.31468 10.0475 6.30995 10.4067 6.52865 10.6313L8.64757 12.8068C8.70038 12.8612 8.76359 12.9044 8.83343 12.9339C8.90328 12.9634 8.97833 12.9785 9.05414 12.9784C9.19951 12.9785 9.33936 12.9227 9.44483 12.8227L13.4746 9.00107C13.702 8.78538 13.7116 8.42611 13.4957 8.1987Z"
				fill="white"
			/>
		</svg>
	);
};

export default CricleWrite;
