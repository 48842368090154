/* eslint-disable react/jsx-key */
/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from 'react-scroll';

import { useTranslation } from 'react-i18next';
import { NavMenuLinks } from '../../constants/NavbarMenuList';

const NavLinks = ({ activeSection = '', setOpen }) => {
	const { t } = useTranslation();
	const [heading, setHeading] = useState('');
	const [subHeading, setSubHeading] = useState('');

	const handleClick = () => {
		try {
			setOpen(false);
		} catch (error) {
			console.log(error.message);
		}
	};

	return (
		<>
			{NavMenuLinks.map((link) => (
				<div key={link.name}>
					<div className="group text-left md:cursor-pointer">
						{link?.originalLink ? (
							<RouterLink to={link.link} target="_blank" smooth duration={800}>
								<h1
									className={` group flex items-center justify-between font-Montserrat text-base font-medium normal-case  hover:text-blue-200 active:text-[#1098FF] md:pr-0 ${
										activeSection.toLowerCase() === link.name.toLowerCase()
											? 'bg-blue-200'
											: 'text-[#A6AFBD]'
									}`}
									onClick={() => {
										heading !== link.name ? setHeading(link.name) : setHeading('');
										setSubHeading('');
									}}
								>
									{t(link.name)}*
								</h1>
							</RouterLink>
						) : (
							<Link to={link.link} smooth duration={800} onClick={handleClick}>
								<h1
									className={`group flex items-center justify-between font-Montserrat text-base font-medium normal-case text-[#A6AFBD] hover:text-blue-200 active:text-[#1098FF] md:pr-0 ${
										activeSection.toLowerCase() === link.name.toLowerCase()
											? 'text-blue-200'
											: 'text-[#A6AFBD]'
									}`}
									onClick={() => {
										heading !== link.name ? setHeading(link.name) : setHeading('');
										setSubHeading('');
									}}
								>
									{t(link.name)}
								</h1>
							</Link>
						)}

						{link?.submenu && (
							<div className="absolute top-20 hidden hover:md:block group-hover:md:block">
								<div className="py-3">
									<div
										className="absolute left-3 mt-1 h-4
                  w-4 rotate-45 bg-white"
									/>
								</div>
								<div className="grid grid-cols-3 gap-10 bg-white p-5">
									{link?.sublinks.map((mysublinks) => (
										<div>
											<h1 className="text-lg font-semibold">{mysublinks.Head}</h1>
											{mysublinks?.sublink.map((slink) => (
												<li className="my-2.5 text-sm text-gray-600">
													<a href={slink.link} className="hover:text-primary">
														{slink.name}
													</a>
												</li>
											))}
										</div>
									))}
								</div>
							</div>
						)}
					</div>

					{/* Mobile menus */}
					<div
						className={`
          ${heading === link.name ? 'md:hidden' : 'hidden'}
        `}
					>
						{/* sublinks */}
						{link.submenu &&
							link?.sublinks?.map((slinks) => (
								<div>
									<div>
										<h1
											onClick={() =>
												subHeading !== slinks.Head ? setSubHeading(slinks.Head) : setSubHeading('')
											}
											className="flex items-center justify-between py-4 pl-7  font-semibold  md:pr-0"
										>
											{slinks.Head}

											<span className="inline text-xl md:ml-2 md:mt-1">
												<ion-icon
													name={`${subHeading === slinks.Head ? 'chevron-up' : 'chevron-down'}`}
												/>
											</span>
										</h1>
										<div className={`${subHeading === slinks.Head ? 'md:hidden' : 'hidden'}`}>
											{slinks.sublink.map((slink) => (
												<li className="py-3 pl-14">
													<a href={slink.link}>{slink.name}</a>
												</li>
											))}
										</div>
									</div>
								</div>
							))}
					</div>
				</div>
			))}
		</>
	);
};
export default NavLinks;
