/* eslint-disable no-nested-ternary */
import { Navigate, Route, Routes } from 'react-router-dom';

import useAuth from '../hooks/useAuth';

const AuthenticatedRoutes = ({ children = null, redirectTo = '/' }) => {
	const { state: isAuthenticated } = useAuth();
	return isAuthenticated ? children : <Navigate to={redirectTo} />;
};

const UnAuthenticatedRoutes = ({ children = null, redirectTo = '/jobs' }) => {
	const { state: isAuthenticated } = useAuth();
	return !isAuthenticated ? children : <Navigate to={redirectTo} />;
};

const routeConfig = (routes = []) => {
	if (!routes) return <div />;

	return (
		<Routes>
			{routes?.map((route) => {
				return (
					<Route
						key={route.name}
						path={route.path}
						element={
							route.public ? (
								<UnAuthenticatedRoutes>
									<route.component />
								</UnAuthenticatedRoutes>
							) : route.protected ? (
								<AuthenticatedRoutes>
									<route.component />
								</AuthenticatedRoutes>
							) : (
								<route.component />
							)
						}
					/>
				);
			})}
		</Routes>
	);
};

export default routeConfig;
