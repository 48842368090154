import { useTranslation } from 'react-i18next';
import careerImg from '../../assets/images/career.png';
import CircleTick from '../atoms/CircleTick';
import Heading from '../atoms/Heading';
import Text from '../atoms/Text';

const CareerSection = () => {
	const { t } = useTranslation();

	return (
		<section
			className="relative pb-[100px] pt-[30px] xl:py-[120px] 2xl:mb-[100px]"
			id="integration"
		>
			<div className="container mx-auto w-full flex-col gap-5 pt-10 md:flex-row md:justify-between lg:flex">
				<div className="flex-[50%] px-5 ">
					<div className="flex h-full w-full max-w-[500px] flex-col justify-center">
						<div className="relative">
							<div className="outlined-number">12</div>
							<Heading
								text={t('Career Page Integration')}
								className={t('relative bottom-8 whitespace-nowrap md:text-[32px]')}
							/>
							<Text className="mb-5 font-Montserrat text-base font-medium leading-[30px] text-[#687497]">
								{t(
									'Showcase your brand and job opportunities effortlessly with Career Page Integration. Our notable feature lets you seamlessly display created jobs on your preferred website, preserving brand value and reputation with ease.'
								)}
							</Text>
						</div>
						<div>
							<div className="my-2 flex items-center gap-[10px] px-3">
								<CircleTick className="h-[24px] w-[24px]" />
								<h5 className="mt-0 font-Montserrat text-base font-bold leading-[30px] text-[#02060C] md:text-[18px]">
									{t('Easy to Implement')}
								</h5>
							</div>
							<div className="my-2 flex items-center gap-[10px] px-3">
								<CircleTick className="h-[24px] w-[24px]" />
								<h5 className="mt-0 font-Montserrat text-base font-bold leading-[30px] text-[#02060C] md:text-[18px]">
									{t('Technology Independents')}
								</h5>
							</div>
							<div className="my-2 flex items-center gap-[10px] px-3">
								<CircleTick className="h-[24px] w-[24px]" />
								<h5 className="mt-0 font-Montserrat text-base font-bold leading-[30px] text-[#02060C] md:text-[18px]">
									{t('Developer Guild')}
								</h5>
							</div>
						</div>
					</div>
				</div>

				<div className="mx-auto h-full flex-[50%]">
					<img
						src={careerImg}
						alt="Career Page Integration"
						className="lg:absolute lg:-end-[0px] lg:top-1/2 lg:w-[55%] lg:-translate-y-[40%]"
					/>
				</div>
			</div>
		</section>
	);
};

export default CareerSection;
