/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { useQuery } from '@tanstack/react-query';
import debounce from 'lodash.debounce';
import { useRef, useState } from 'react';
import { IoSearch } from 'react-icons/io5';
import { TbMenuDeep } from 'react-icons/tb';
import { Link, useNavigate } from 'react-router-dom';

import { getJobs } from '../../apis/jobApIs';
import MobileLogo from '../../assets/images/mobile-logo.png';
import Logo from '../../assets/svgs/Logo';
import useOutClick from '../../hooks/useOutClick';

const JobsSearch = ({ className = '', toggleSearch = false, setToggleSearch = null }) => {
	const searchRef = useRef();
	const [query, setQuery] = useState('');
	const navigate = useNavigate();

	useOutClick(searchRef, () => {
		setQuery('');
		setToggleSearch(false);
	});

	// const location = useLocation();
	// const fullUrl = window.location.origin + location.pathname + location.search + location.hash;

	const { data } = useQuery({
		queryKey: ['todos', query],
		queryFn: () => getJobs(query),
	});

	// eslint-disable-next-line func-names
	const debounce_fun = debounce(function (e) {
		setQuery(e.target.value);
	}, 1000);

	const handleSelectedJob = (id) => {
		navigate(`/jobs/${id}`);
		setQuery('');
	};

	return (
		<div
			ref={searchRef}
			className={`flex w-full items-center justify-between gap-6 md:w-[70%] ${className}`}
		>
			<div className={`sm:block lg:mr-10 ${toggleSearch && 'hidden'}`}>
				<Link to="/jobs">
					<div className="hidden justify-end align-bottom md:flex">
						<Logo />
						<span className="mt-6 text-sm font-medium text-gray-300">Beta</span>
					</div>

					<div className="ml-3 flex justify-end align-bottom md:hidden">
						<img src={MobileLogo} alt="hrclerks" style={{ height: '50px', width: '50px' }} />
					</div>
				</Link>
			</div>

			<div
				className={`relative hidden
            h-[48px] w-full max-w-[795px] items-center justify-between rounded-[40px] border border-[#A6AFBD] md:flex`}
			>
				<div className="flex w-full items-center">
					<div className="pl-5">
						<IoSearch size="16px" color="#A6AFBD" />
					</div>

					<input
						id="search"
						type="text"
						className="min-w-full border-none bg-transparent p-2 font-Montserrat text-[14px] font-medium placeholder-[#A6AFBD] focus:border-none focus:outline-none focus:ring-0"
						placeholder="Search Job Circular"
						onChange={(e) => debounce_fun(e)}
					/>
				</div>
				{/* <div className="mr-5 flex h-[36px] w-[36px] items-center justify-center rounded-full bg-[#F7F9FD] px-[4.5px] py-[3px]"> */}
				{/* <TbMenuDeep color="#717E91" /> */}
				{/* <SearchSVG /> */}
				{/* </div> */}
				{query !== '' && (
					<ul
						className={`absolute start-0 top-[60px] w-full rounded-xl bg-white shadow-sm ${
							!data?.jobs && 'hidden'
						}`}
					>
						{data?.jobs?.map(({ title, id }) => (
							<li
								key={id}
								onClick={() => handleSelectedJob(id)}
								className="cursor-pointer border-b border-blue-100 px-10 py-3 first:rounded-t-md last:rounded-b-md hover:bg-blue-100"
							>
								{title}
							</li>
						))}
					</ul>
				)}
			</div>

			<div
				className={`overflow
            -x-hidden relative flex max-w-[795px] items-center justify-between rounded-[40px] border border-[#A6AFBD] transition-all md:hidden ${
							toggleSearch
								? 'block h-[48px] w-full'
								: 'flex h-[40px] w-[40px] items-center justify-center'
						}`}
			>
				<div className={`flex w-full items-center ${toggleSearch ? '' : 'w-0 overflow-x-hidden'}`}>
					<button
						type="button"
						aria-label="button"
						onClick={() => setToggleSearch((oldState) => !oldState)}
						className={`transition-all ${toggleSearch ? 'py-4 pl-5' : 'p-3'}`}
					>
						<IoSearch size="16px" color="#A6AFBD" />
					</button>

					<input
						id="search"
						type="text"
						className={`min-w-full border-none bg-transparent p-2 font-Montserrat text-[14px] font-medium placeholder-[#A6AFBD] transition-all focus:border-none focus:outline-none focus:ring-0 ${
							!toggleSearch && 'hidden'
						}`}
						placeholder={toggleSearch ? 'Search Job Circular' : ''}
						onChange={(e) => debounce_fun(e)}
					/>
				</div>
				<div
					className={`mr-5 flex h-[36px] w-[36px] items-center justify-center rounded-full bg-[#F7F9FD] px-[4.5px] py-[3px] ${
						!toggleSearch && 'hidden'
					}`}
				>
					<TbMenuDeep color="#717E91" />
				</div>
				{query !== '' && (
					<ul
						className={`absolute start-0 top-[60px] w-full rounded-xl bg-white shadow-sm ${
							!data?.jobs && 'hidden'
						}`}
					>
						{data?.jobs?.map(({ title, id }) => (
							<li
								key={id}
								onClick={() => handleSelectedJob(id)}
								className="cursor-pointer border-b border-blue-100 px-10 py-3 first:rounded-t-md last:rounded-b-md hover:bg-blue-100"
							>
								{title}
							</li>
						))}
					</ul>
				)}
			</div>
		</div>
	);
};

export default JobsSearch;
