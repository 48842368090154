import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

import openGraphImage from '../../assets/images/open-geaph-image.png';
import { FooterContents } from '../../components/atoms/FooterContents';
import Navbar from '../../components/molecules/Navbar';
import ContactSales from '../../components/templates/ContactSales';

const ContactSalesTeamPage = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<>
			<Helmet>
				<link rel="canonical" href="https://hrclerks.com" />
				<title>Sales Form | Elevate your hiring process with HrClerks ATS</title>
				<meta
					name="description"
					content="Sales Form | Elevate your hiring process with HrClerks ATS – a cutting-edge Applicant Tracking System designed to simplify recruitment. Explore powerful features for seamless talent management and transform your hiring experience today."
				/>
				{/* Open Graph */}
				<meta property="article:author" content="HrClerks Talent Solution" />
				<meta property="article:publisher" content="https://www.hrclerks.com" />
				<meta property="og:site_name" content="hrclerks.com" />
				<meta
					name="og:title"
					content="Sales Form | Elevate your hiring process with HrClerks ATS"
				/>
				<meta
					name="og:description"
					content="Sales Form | Elevate your hiring process with HrClerks ATS – a cutting-edge Applicant Tracking System designed to simplify recruitment. Explore powerful features for seamless talent management and transform your hiring experience today."
				/>
				<meta name="og:image" content={openGraphImage} />
				<meta property="og:image:width" content="1200" />
				<meta property="og:image:height" content="630" />
				<meta name="og:type" content="website" />
				<meta name="og:url" content="https://www.hrclerks.com/contact-sales" />
				{/* Twitter */}
				<meta property="article:author" content="HrClerks Talent Solution" />
				<meta property="article:publisher" content="https://www.hrclerks.com" />
				<meta property="twitter:site_name" content="hrclerks.com" />
				<meta
					name="twitter:title"
					content="Sales Form | Elevate your hiring process with HrClerks ATS"
				/>
				<meta
					name="twitter:description"
					content="Sales Form | Elevate your hiring process with HrClerks ATS – a cutting-edge Applicant Tracking System designed to simplify recruitment. Explore powerful features for seamless talent management and transform your hiring experience today."
				/>
				<meta name="twitter:image" content={openGraphImage} />
				<meta property="twitter:image:width" content="1200" />
				<meta property="twitter:image:height" content="630" />
				<meta name="twitter:url" content="https://www.hrclerks.com/contact-sales" />
				<meta
					name="twitter:card"
					content="Sales Form | Elevate your hiring process with HrClerks ATS"
				/>
			</Helmet>

			<section className="relative bg-white">
				<Navbar />
				<ContactSales />
				<FooterContents />
			</section>
		</>
	);
};

export default ContactSalesTeamPage;
