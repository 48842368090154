import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import CircleWriteSVG from '../../assets/svgs/CircleWriteSVG';
import Button from '../atoms/Button';
import EmailButton from '../atoms/EmailButton';

const SubscriptionPlans = ({ setIsOpen }) => {
	const location = useLocation();
	const { t } = useTranslation();

	return (
		<div className="mx-auto grid max-w-[830px] grid-cols-1 gap-10 md:grid-cols-2">
			<div className="w-full pt-5 text-center">
				<h5 className="mb-2 font-Montserrat text-base font-bold text-[#81838C] md:mb-8 md:mt-5">
					{t('Enterprise')}
				</h5>

				<div className="font-Montserrat font-medium text-[#182748] md:mb-10 md:mt-1">
					<Link to="/contact-sales" className="cursor-pointer">
						<p className="underline">{t('Contact Sales')}</p>{' '}
					</Link>

					<p className="text-center leading-3">{t('for a price estimate')}</p>
				</div>

				<ul className="mt-2">
					<li className="flex flex-wrap items-center justify-center gap-2 border-b border-[#EAECED] py-2 font-Montserrat text-base font-normal leading-[30px] text-[#182748]">
						<p className="font-medium">✓{t('Unlimited')}</p>{' '}
						<p className="leading-[6px]">{t('Verified Applicants')}</p>
					</li>
					<li className="flex flex-wrap items-center justify-center gap-2 border-b border-[#EAECED] py-2 font-Montserrat text-base font-normal leading-[30px] text-[#182748]">
						<p className="font-medium">✓{t('Unlimited')}</p>{' '}
						<p className="leading-[6px]">{t('Live Job Application')}</p>
					</li>
					<li className="flex flex-wrap items-center justify-center gap-2 border-b border-[#EAECED] py-2 font-Montserrat text-base font-normal leading-[30px] text-[#182748]">
						<p className="font-medium">✓{t('Unlimited')}</p>{' '}
						<p className="leading-[6px]">{t('Custom Screening Questions')}</p>
					</li>
					<li className="flex flex-wrap items-center justify-center gap-2 border-b border-[#EAECED] py-2 font-Montserrat text-base font-normal leading-[30px] text-[#182748]">
						<p className="font-medium">✓{t('Unlimited')}</p>
						<p className="leading-[6px]">{t('Talent Pools')}</p>
					</li>
					<li className="border-b border-[#EAECED] py-2 font-Montserrat text-base font-normal leading-[30px] text-[#182748]">
						✓ {t('Spam Control')}
					</li>
					<li className="border-b border-[#EAECED] py-2 font-Montserrat text-base font-normal leading-[30px] text-[#182748]">
						✓ {t('Talent Tagging')}
					</li>
					<li className="border-b border-[#EAECED] py-2 font-Montserrat text-base font-normal leading-[30px] text-[#182748]">
						✓ {t('Applicant Rating')}
					</li>
					<li className="border-b border-[#EAECED] py-2 font-Montserrat text-base font-normal leading-[30px] text-[#182748]">
						✓ {t('Job Board Integration')}
					</li>
					<li className="border-b border-[#EAECED] py-2 font-Montserrat text-base font-normal leading-[30px] text-[#182748]">
						✓ {t('Career Page Integration')}
					</li>
				</ul>
				<EmailButton>
					<div className="mt-5 flex items-center justify-center gap-2">
						<p className="font-Montserrat text-base font-bold text-[#1098ff]">
							{t('Contact Sales')}
						</p>
						<CircleWriteSVG />
					</div>
				</EmailButton>
			</div>

			<div className="w-full rounded-lg bg-[#223645] py-10 text-center">
				<Button
					title="Premium"
					className="mb-3 rounded-full bg-[#00FFAB] px-10 py-1 font-Montserrat text-base font-bold text-[#223645] "
				/>
				<h2 className="font-Montserrat text-[34px] font-bold text-white line-through">৳980.0</h2>
				<p className="font-Montserrat text-base font-normal text-white md:mb-2">{t('Per month')}</p>
				<ul className="my-4">
					<li className="py-2 font-Montserrat text-base font-normal leading-[30px] text-white">
						10000 {t('Verified Applicants')}
					</li>
					<li className=" py-2 font-Montserrat text-base font-normal leading-[30px] text-white">
						5 {t('Live Job Application')}
					</li>
					<li className=" py-2 font-Montserrat text-base font-normal leading-[30px] text-white">
						5 {t('Custom Screening Questions')}
					</li>
					<li className=" py-2 font-Montserrat text-base font-normal leading-[30px] text-white">
						5 {t('Talent Pools')}
					</li>
					<li className=" py-2 font-Montserrat text-base font-normal leading-[30px] text-white">
						✓ {t('Spam Control')}
					</li>
					<li className=" py-2 font-Montserrat text-base font-normal leading-[30px] text-white">
						✓ {t('Talent Tagging')}
					</li>
					<li className=" py-2 font-Montserrat text-base font-normal leading-[30px] text-white">
						✓ {t('Applicant Rating')}
					</li>
					<li className=" py-2 font-Montserrat text-base font-normal leading-[30px] text-white">
						✓ {t('Job Board Integration')}
					</li>
					<li className=" py-2 font-Montserrat text-base font-normal leading-[30px] text-white">
						✓ {t('Career Page Integration')}
					</li>
				</ul>
				<Link to="/signup" className={location.pathname !== '/' && 'hidden'}>
					<div className="mt-6 flex items-center justify-center gap-2">
						<Button
							title={t('Get started')}
							className="font-Montserrat text-base font-bold text-white"
						/>
						<CircleWriteSVG className="text-white" />
					</div>
				</Link>
				<div className="mt-6 hidden items-center justify-center gap-2">
					<Button
						onClick={() => setIsOpen(true)}
						title={t('Get started')}
						className="font-Montserrat text-base font-bold text-white"
					/>
					<CircleWriteSVG className="text-white" />
				</div>

				{location.pathname !== '/' && (
					<div className="mt-6 flex items-center justify-center gap-2">
						<Button
							onClick={() => setIsOpen(true)}
							title={t('Current')}
							className="font-Montserrat text-base font-bold text-white"
							disabled
						/>
						<CircleWriteSVG className="text-white" />
					</div>
				)}
			</div>
		</div>
	);
};

export default SubscriptionPlans;
