/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-no-comment-textnodes */
import { Field, useField } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import EyeClosed from '../../assets/svgs/EyeClosed';
import EyeOpen from '../../assets/svgs/EyeOpen';

const PasswordInputField = ({
	name = 'textInput',
	placeholder = 'Type here ...',
	className = '',
	...props
}) => {
	const [field, meta] = useField({ name });
	const [isEyeClosed, setIsEyeClosed] = useState(false);
	const { t } = useTranslation();

	const handleEyeClosed = () => {
		setIsEyeClosed(!isEyeClosed);
	};

	return (
		<div className="mb-[20px] w-full rounded-lg">
			<div className="relative w-full">
				<Field
					type={isEyeClosed ? 'text' : 'password'}
					className={`w-full focus:outline-none ${
						meta.touched && meta.active ? 'bg-transparent' : ''
					} ${className}`}
					name={name}
					placeholder={placeholder}
					// eslint-disable-next-line react/jsx-props-no-spreading
					{...field}
					// eslint-disable-next-line react/jsx-props-no-spreading
					{...props}
				/>

				<div
					onClick={handleEyeClosed}
					className="absolute end-5 top-1/2 -translate-y-1/2 cursor-pointer"
				>
					{isEyeClosed ? <EyeOpen /> : <EyeClosed />}
				</div>
			</div>

			{meta.touched && meta.error ? (
				<div className="mt-1 text-start text-sm text-red">{t(meta.error)}</div>
			) : null}
		</div>
	);
};

export default PasswordInputField;
